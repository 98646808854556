import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";

import { Controller } from "react-hook-form";
import classnames from "classnames";
import { isArray, isObject, isString } from "lodash";

const useStyles = makeStyles({
  select: {
    width: "97%",
    marginBottom: "19px",
    background: "#1A162C",
    border: "1px solid #342D5C",
    borderRadius: "5px",
    "&.MuiFormControl-root": {
      "&.required": {
        borderColor: "#b30000",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#7477A0",
      top: "-7px",
      "&.MuiFormLabel-filled, &.Mui-focused": {
        top: 0,
      },
    },
    "& .MuiOutlinedInput-root": {
      color: "#7477A0",
    },
  },
  errorText: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginRight: "14px",
    marginBottom: 0,
    marginLeft: "14px",
    position: "absolute",
    bottom: "-4px",
  },
});
interface Option {
  id: number;
  name: string;
  errors: any;
}

const CustomSelect = (props: any) => {
  const {
    options,
    className,
    errors,
    size = "medium",
    errorMessage = "Categories Required",
    label = "Choose category",
    selectLabel = "Prize categories",
    placeholder = "Choose category for your prize",
    name = "categories",
    multiple = true,
    isCommon = false,
    required = false,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <FormControl
      className={classnames([classes.select], className)}
      fullWidth
      variant="outlined"
    >
      <InputLabel required={required} htmlFor={`${name}_label`}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={rest}
        rules={{ required: errorMessage }}
        render={({ field: { onChange, value: updValue } }) => {
          const value = isArray(updValue)
            ? updValue.map((item: string | { id: number; name: string }) =>
                isString(item) ? item : JSON.stringify(item)
              )
            : updValue;
          return (
            <Select
              size={size}
              value={value}
              onChange={onChange}
              label={selectLabel}
              placeholder={placeholder}
              labelId={`${name}_label`}
              renderValue={(selected: string | string[]) => {
                return multiple && isArray(selected)
                  ? (selected as string[])
                      .map((item: string) =>
                        isString(item)
                          ? JSON.parse(item).name
                          : (item as { id: number; name: string })?.name
                      )
                      .join(", ")
                  : isCommon
                  ? (selected as string)
                  : (
                      JSON.parse(selected as string) as {
                        id: number;
                        name: string;
                      }
                    ).name;
              }}
              multiple={multiple}
            >
              {options.map((option: Option | string) => {
                return (
                  <MenuItem
                    key={isCommon ? (option as string) : (option as Option).id}
                    value={
                      isCommon
                        ? (option as string)
                        : JSON.stringify({
                            id: (option as Option).id,
                            name: (option as Option).name,
                          } as Option)
                    }
                  >
                    {multiple && !isCommon && (
                      <Checkbox
                        checked={value
                          .map((item: string) => {
                            return isObject(item)
                              ? (item as { name: string; id: number }).name
                              : JSON.parse(item).name;
                          })
                          .includes((option as Option).name)}
                      />
                    )}
                    <ListItemText
                      primary={
                        isCommon ? (option as string) : (option as Option).name
                      }
                    />
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
        defaultValue={[]}
      />
      {/* errors will return when field validation fails  */}
      {(errors?.[name] as any)?.type === "required" && (
        <span className={classes.errorText}>
          {(errors?.[name] as any)?.message}
        </span>
      )}
    </FormControl>
  );
};
export default CustomSelect;
