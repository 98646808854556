import React from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useQuery } from "react-query";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

import Modal from "components/modals/Modal";
import useModals from "hooks/useModals";
import { MODALS } from "../../constants";

const useStyles = makeStyles({
  titleWrapper: {
    paddingBottom: "20px",
  },
  unverified: {
    color: "red",
    paddingLeft: "40px",
  },
  itemContent: {
    width: "100%",
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
    "&:first-of-type": {
      paddingRight: "30px",
    },
    "& .MuiTypography-h6": {
      color: "white",
    },
  },
  image: {
    width: "180px",
    height: "100px",
    objectFit: "contain",
    border: "1px solid rgb(52, 45, 92)",
  },
  imageWrapper: {
    display: "flex",
    "&>div": {
      flexBasis: "50%",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
  },
  linkField: {
    wordBreak: "break-all",
  },
  tabs: {
    "& .MuiTypography-root": {
      color: "white",
    },
    "& .MuiButtonBase-root": {
      color: "#7B7B8B",
      "&.Mui-selected": {
        color: "white",
      },
    },
  },
  buttonPosition: {
    margin: "0 15px 0 auto",
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const getProfile = (): Promise<any> =>
  axios.get(`/retailer-profile/profile`).then((res) => res);

const Profile = () => {
  const classes = useStyles();
  const { open, handleOpen, handleClose } = useModals();
  const { data }: { data: any; isLoading: any } = useQuery(
    "profile",
    () => getProfile(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const imageBigUrl = data?.data?.images?.find(
    (item: any) => item.type === "PROFILE"
  )?.url;
  const imageSmallUrl = data?.data?.images?.find(
    (item: any) => item.type === "LOGO_LARGE"
  )?.url;
  const imageInteriorUrl = data?.data?.images?.find(
    (item: any) => item.type === "INTERIOR"
  )?.url;

  return (
    <>
      <Container>
        <Grid container spacing={0} flexDirection="column">
          <div className={classes.titleWrapper}>
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <h1>Profile page</h1>
              {data?.data?.status === "UNVERIFIED" && (
                <h4 className={classes.unverified}>
                  This account is unverified
                </h4>
              )}
              <Button
                onClick={() =>
                  handleOpen(MODALS.EDIT_PROFILE, {
                    name: data?.data?.name,
                    address: data?.data?.address,
                    url: data?.data?.socialReview?.url,
                    images: data?.data?.images,
                    geoLocation: data?.data?.geoLocation,
                  })
                }
                variant="contained"
                color="primary"
                endIcon={<EditIcon />}
                className={classes.buttonPosition}
              >
                Edit Profile
              </Button>
            </Grid>
          </div>
          <Grid display="flex">
            <div className={classes.itemContent}>
              <Typography variant="h6" gutterBottom component="div">
                Merchants name
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {data?.data?.name || ""}
              </Typography>
              <Typography variant="h6" gutterBottom component="div">
                Full address
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {data?.data?.address || ""}
              </Typography>
              <Typography variant="h6" gutterBottom component="div">
                Link
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {data?.data?.socialReview?.url ? (
                  <a
                    href={data?.data?.socialReview?.url}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.linkField}
                  >
                    {data?.data?.socialReview?.url}
                  </a>
                ) : (
                  "None"
                )}
              </Typography>
              <div className={classes.imageWrapper}>
                <div>
                  <Typography variant="h6" gutterBottom component="div">
                    Profile photo
                  </Typography>
                  {imageBigUrl ? (
                    <img className={classes.image} src={imageBigUrl} alt="" />
                  ) : null}
                </div>
                <div>
                  <Typography variant="h6" gutterBottom component="div">
                    Logo photo
                  </Typography>
                  {imageSmallUrl ? (
                    <img className={classes.image} src={imageSmallUrl} alt="" />
                  ) : null}
                </div>
                <div>
                  <Typography variant="h6" gutterBottom component="div">
                    Interior photo
                  </Typography>
                  {imageInteriorUrl ? (
                    <img
                      className={classes.image}
                      src={imageInteriorUrl}
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            </div>
            {/* <div className={classNames(classes.itemContent, classes.tabs)}>
              <Typography>Tips:</Typography>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Where we use this informaion" {...a11yProps(0)} />
                  <Tab label="Why we need your photo" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                Where we use this informaion
              </TabPanel>
              <TabPanel value={value} index={1}>
                Why we need your photo
              </TabPanel>
            </div> */}
          </Grid>
        </Grid>
      </Container>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.EDIT_PROFILE}
      />
    </>
  );
};
export default Profile;
