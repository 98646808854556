export function handleResizeTable(
  width: any,
  height: any,
  elementHeight?: any
) {
  const HEADER_HEIGHT = 405;
  const HEADER = 70;
  const PAGINATION = 60;
  const ROW = 52;

  let rows =
    (height -
      (elementHeight ? elementHeight?.offsetHeight : HEADER_HEIGHT) -
      2.5 * PAGINATION -
      HEADER) /
    ROW;

  return Math.round(rows); // TODO need calculate
}
