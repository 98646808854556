import React from "react";
import Grid from "@mui/material/Grid";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useDropzone } from "react-dropzone";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { v4 as uuid } from "uuid";

import Thumb from "./Thumb";
import { MediaGP } from "models/media";
import { MODALS } from "../../constants";
import useModals from "hooks/useModals";
import Modal from "components/modals/Modal";
import { updatePhotoSize } from "helpers/updatePhotoSize";
const useStyles = makeStyles({
  dragAndDrop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  dragAndDropFixed: {
    height: "188",
  },
  dndContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "34px",
    cursor: "pointer",
    borderRadius: "5px",
    background: "0% 0% no-repeat padding-box padding-box rgb(26, 22, 44)",
    "& svg": {
      color: "#EB62FF",
      background: "#eb62ff33",
      padding: "10px",
      borderRadius: "15px",
    },
  },
  dndContentSingle: {
    padding: " 34px 2px",
    width: "125px",
  },
  dndContentMultipl: {
    padding: "23px",
  },
  inputWrapper: {
    "&.MuiFormControl-root": {
      margin: "25px 0 20px 0",
      "& .MuiInputLabel-root": {
        transform: "translate(0, 0) scale(0.75)",
        top: "-25px",
        color: "#7477A0",
      },
    },
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    marginLeft: 10,
    width: "100%",
  },
  thumb: {
    display: "inline-flex",

    width: 100,
    height: 100,
    boxSizing: "border-box",
    margin: "0 auto 8px auto",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  errorMsg: {
    width: "100%",
    position: "absolute",
    bottom: "-22px",
    textAlign: "center",
    color: "red",
    fontSize: "12px",
  },
});

const getMediaGPFile = (
  file: globalThis.File,
  isVideo: boolean,
  isMultiple: boolean,
  index: number,
  crop?: boolean
) => {
  return {
    media: file,
    preview: URL.createObjectURL(file),
    description: "",
    uuid: uuid(),
    error: true,
    type: isVideo ? "VIDEO" : isMultiple ? `REGULAR` : "COVER_PHOTO",
    ...(isVideo
      ? {}
      : {
          isCropped: crop || false,
        }),
  };
};

const DragAndDropGP = ({
  label,
  name,
  images,
  uploadPhoto = (files: any[]) => {},
  isMultiple = false,
  buttonLabel = "Add Prize picture",
  accept,
  onChangeDescription,
  required,
  type,
  forceUpdate,
}: any) => {
  const classes = useStyles();
  const { open, handleOpen, handleClose } = useModals();

  const [errorMsg, setErrorMsg] = React.useState<{ [key: string]: string }>({});

  const removeFile = (fileId: any) => {
    const resultUploadFiles = images.reduce((acc: MediaGP[], item: MediaGP) => {
      if (item?.mediaId === fileId) return [...acc, { ...item, removed: true }];
      if (item?.uuid === fileId) return acc;
      return [...acc, item];
    }, []);

    uploadPhoto(resultUploadFiles);
  };

  const updateCropPhoto = (file: any, index: number) => {
    let resultFiles = images;

    if (!resultFiles?.length) {
      const isVideo = file.type.includes("video");
      resultFiles[0] = getMediaGPFile(file, isVideo, isMultiple, index, true);
    } else {
      resultFiles[index] = {
        ...resultFiles[index],
        preview: URL.createObjectURL(file),
        media: file,
        isCropped: true,
      };
    }

    uploadPhoto(resultFiles);
    forceUpdate();
  };

  const openCropModal = async (media: any) => {
    handleOpen(MODALS.CROP_IMAGE, {
      src: URL.createObjectURL(media),
      updatePhoto: (file: any) => updateCropPhoto(file, 0),
      aspect: 0.67,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: globalThis.File[]) => {
      const returnPhotos: MediaGP[] = acceptedFiles.reduce(
        (acc: MediaGP[], file: globalThis.File, index: number) => {
          const isVideo = file.type.includes("video");
          if (!isVideo && file.size > 8388608) {
            setErrorMsg({
              [type]: "File is larger than 8 mb",
            });
            return acc;
          }
          setErrorMsg({
            [type]: "",
          });
          return [...acc, getMediaGPFile(file, isVideo, isMultiple, index)];
        },
        []
      );
      const result = [...images, ...returnPhotos].reduce(
        (acc, image, index) => {
          if (index > 7) {
            return acc;
          }
          if (image.type === "REGULAR") {
            return acc.concat({
              ...image,
              type: `REGULAR${acc.length + 1}`,
              isCropped: image?.isCropped || false,
            });
          }
          return acc.concat(image);
        },
        []
      );
      uploadPhoto(result);
      if (type === "MAIN") {
        const isVideo = result[0]?.media.type.includes("video");
        !isVideo && openCropModal(result[0]?.media);
      }
    },
    onDropRejected: (rejectedFiles) => {
      uploadPhoto([...images]);
      setErrorMsg({
        [type]:
          rejectedFiles[0].errors[0].code === "file-too-large"
            ? "File is larger than 8 mb"
            : "Image load error",
      });
    },
    multiple: isMultiple,
    maxFiles: 8,
    accept: accept,
    maxSize: type === "MAIN" ? Infinity : 8388608,
  });

  const thumbs = images.map((file: MediaGP, index: number) => {
    if (isEmpty(file)) {
      return null;
    }
    return (
      <Thumb
        key={file.mediaId || file.uuid}
        file={file}
        onRemove={() => removeFile(file?.mediaId || file?.uuid)}
        isMain={!isMultiple}
        onChangeDescription={onChangeDescription}
        updatePhoto={(file: any) => updateCropPhoto(file, index)}
      />
    );
  });

  return (
    <>
      <FormControl className={classes.inputWrapper}>
        {label && (
          <InputLabel required={required} shrink htmlFor={`input-${name}`}>
            {label}
          </InputLabel>
        )}
        <div
          className={classNames([classes.dragAndDrop], {
            [classes.dragAndDropFixed]: !isMultiple,
          })}
        >
          <Grid container>
            {!!thumbs.length && thumbs}
            {((!isMultiple && images.length < 1) ||
              (isMultiple && images.length < 8)) && (
              <div id={`input-${name}`} {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <div
                    className={classNames([classes.dndContent], {
                      [classes.dndContentMultipl]: isMultiple,
                      [classes.dndContentSingle]: !isMultiple,
                    })}
                  >
                    <AddAPhotoIcon />
                    {!isMultiple && <p>{buttonLabel}</p>}
                    <p key={type} className={classes.errorMsg}>
                      {errorMsg?.[type]}
                    </p>
                  </div>
                )}
              </div>
            )}
          </Grid>
        </div>
      </FormControl>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.CROP_IMAGE}
      />
    </>
  );
};

export default DragAndDropGP;
