import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";

import { set } from "helpers/storage";
import { ROUTES, USER_TOKEN } from "../constants";
import { UserInfo, Tokens } from "models/user";

export interface LoginParams {
  username: string;
  password: string;
}

export const login = async ({
  username,
  password,
}: LoginParams): Promise<Tokens> => {
  const {
    data: { authToken, refreshToken },
  } = await axios.post<Tokens>("retailer/public/auth", {
    username,
    password,
  });

  return {
    authToken,
    refreshToken,
  };
};

export const useLogin = (isRedirected = true) => {
  const navigate = useNavigate();

  const mutation = useMutation<UserInfo | null, AxiosError<any>, LoginParams>(
    login,
    {
      onSuccess: (data) => {
        set(USER_TOKEN, data);
        if (isRedirected) {
          navigate(`/${ROUTES.prizeList}`);
        }
      },
      onError: () => {
        if (isRedirected) {
          navigate(`/${ROUTES.login}`);
        }
      },
    }
  );

  return mutation;
};

export default useLogin;
