import { styled } from "@mui/styles";
import TextField from "@mui/material/TextField";

const ModalInput = styled(TextField)(() => ({
  "& .MuiFormHelperText-root": {
    bottom: "18px",
    position: "absolute",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    background: "#1A162C 0% 0% no-repeat padding-box",
    border: "1px solid #342D5C",
    color: "#7477A0",
    marginBottom: "20px",
  },
  "&.MuiTextField-root": {
    "&.required": {
      "& fieldset": {
        borderColor: "#b30000",
      },
    },
  },
  "& .MuiInputLabel-root": {
    color: "#7477A0",
  },
  "& .MuiFilledInput-input": {
    color: "#7477A0",
  },
  "& input": {
    color: "#7477A0",
  },
}));
export default ModalInput;
