import React from "react";

export const useModals = () => {
  const [open, setOpen] = React.useState<{
    [id: string]: { open: boolean; props?: any };
  }>();

  const handleOpen = (modelId: string, props?: any) =>
    setOpen({ [modelId]: { open: true, props } });
  const handleClose = (modelId: string) => {
    setOpen({ [modelId]: { open: false } });
  };

  return { open, handleOpen, handleClose };
};

export default useModals;
