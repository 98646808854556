export const USER_TOKEN = "userToken";
export enum ROUTES {
  login = "login",
  createProfile = "createProfile",
  campaignsList = "campaignsList",
  prizeList = "prizeList",
  profile = "profile",
  resetPassword = "reset-password",
  grandPrizeList = "grand-prize-list",
  offer = "offer",
  createGrandPrize = "create-grand-prize",
}
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum MODALS {
  EDIT_CAMPAIGN_PRIZE = "edit-campaign-prize",
  ADD_CAMPAIGN = "add-campaign",
  EDIT_PROFILE = "edit-profile",
  ADD_OFFER = "add-offer",
  EDIT_OFFER = "edit-offer",
  CHECK_REDEEM = "check-redeem",
  INVITE_EMPLOYEES = "invite-employees",
  CROP_IMAGE = "crop-image",
}

export type ENV = "dev" | "stg" | "prd" | "local";

export const beLinks = {
  local: "https://swiggle-dev.zazmicdemo.com",
  dev: "https://swiggle-dev.zazmicdemo.com",
  stg: "https://swiggle-stg.zazmicdemo.com",
  prd: "https://portal.swiggleit.com",
};
