import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import axios from "axios";
import { ROUTES, EMAIL_REGEXP } from "../../../constants";
import { useStyles, CustomInput } from "../ResetPassword";
import { useMutation } from "react-query";

interface IFormInput {
  email: string;
}

const resetEmail = (email: string): Promise<any> =>
  axios
    .post("/retailer/public/send-reset-password-link", { email })
    .then((res) => res);

const SendEmail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isReset, setIsReset] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<IFormInput>();

  const { mutate, isLoading, isError } = useMutation(
    (email: string) => resetEmail(email),
    {
      onSuccess: () => {
        setIsReset(true);
      },
    }
  );

  const onSubmit = ({ email }: { email: string }) => {
    mutate(email);
  };

  return (
    <form className={classes.customForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.wrapper}>
        <Typography variant="h5">Password Reset</Typography>
        {isReset ? (
          <p>Please check your email.</p>
        ) : (
          <>
            <>
              <p>
                Seems like you forgot password for Swiggle. Please fill your
                email and click to reset your password button
              </p>
              <CustomInput
                variant="filled"
                size="small"
                type="text"
                label="Enter your email"
                className={classes.input}
                {...register("email", {
                  required: true,
                  pattern: EMAIL_REGEXP,
                })}
              />
            </>
            <>
              {errors?.email?.type === "pattern" ? (
                <span>Enter valid email</span>
              ) : null}
              {errors?.email?.type === "wrongEmail" ? (
                <span>Invalid email</span>
              ) : null}
              {isError ? <span> Invalid email</span> : null}
            </>

            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isEmpty(errors) && !isValid}
            >
              Reset my password
            </LoadingButton>
          </>
        )}
        <button
          className={classes.resetBtn}
          onClick={() => navigate(`/${ROUTES.login}`)}
        >
          Back to Login page
        </button>
      </div>
    </form>
  );
};

export default SendEmail;
