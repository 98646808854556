import React, { useContext } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty, debounce } from "lodash";
import Button from "@mui/material/Button";
import classNames from "classnames";
import DragAndDrop from "components/DragAndDrop";
import { ModalInput, AddressAutocomplete } from "components/inputs";
import useFileUpload from "hooks/useFileUpload";
import UserContext from "contexts/userContext";
import FullScreenLoader from "components/FullScreenLoader";

const useStyles = makeStyles({
  titleWrapper: {
    paddingBottom: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px",
    "& > button": {
      margin: "0 10px",
      minWidth: "90px",
    },
  },
  itemContent: {
    width: "100%",
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
    "&:first-of-type": {
      paddingRight: "30px",
    },
    "& .MuiTypography-h6": {
      color: "white",
    },
  },
  contentWrapper: {
    padding: "20px",
    width: "650px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  item: {
    "& > .MuiFormControl-root": {
      width: "98%",
    },
  },
  itemFullWith: {
    "& > .MuiFormControl-root": {
      width: "97.5%",
    },
  },
  buttonPosition: {
    margin: "0 15px 0 auto",
  },
  itemPhoto: {
    "& > .MuiFormControl-root": {
      width: "95%",
    },
  },
  tabs: {
    "& .MuiTypography-root": {
      color: "white",
    },
    "& .MuiButtonBase-root": {
      color: "#7B7B8B",
      "&.Mui-selected": {
        color: "white",
      },
    },
  },
});

export const editProfile = (value: any): Promise<any> =>
  axios.patch("/retailer-profile/profile", value).then((res) => res);

const debounceValue = debounce(
  (value: string, setFormValue: any, clearErrors: any) => {
    if (value.match(/yelp./i)) {
      axios
        .post("/retailer-profile/info-by-url", {
          url: value,
        })
        .then(({ data }) => {
          if (data?.coordinates) {
            setFormValue(
              "geoLocation",
              `${data?.coordinates?.latitude ?? 0},${
                data?.coordinates?.longitude ?? 0
              }`
            );
          }
          if (data.name) {
            setFormValue("name", data.name);
          }
          if (data.location) {
            setFormValue(
              "address",
              (data?.location?.display_address || []).join(" ,")
            );
          }
          clearErrors();
        });
    }
  },
  600
);

const CreateProfile = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [isLoad, setLoad] = React.useState(false);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setFormValue,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: user?.data?.name || "",
      address: user?.data?.address || "",
      url: user?.data?.socialReview?.url || "",
      geoLocation: user?.data?.geoLocation || "",
    },
  });

  register("geoLocation");

  const { currentFiles, updatePhoto, patchPhotos } = useFileUpload(
    user?.data?.images || [],
    "PROFILE",
    "LOGO_LARGE",
    "INTERIOR"
  );

  const { mutate, isLoading } = useMutation(
    (value: any) => editProfile(value),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries(["userProfile"]);
          setLoad(false);
        }, 2000);
      },
    }
  );

  const handleClick = async (data: { [x: string]: any }) => {
    setLoad(true);
    if (!isEmpty(data)) {
      const { url, ...restFields } = data;
      data = {
        ...restFields,
        ...(url ? { socialReview: { url: url } } : {}),
        ...(user?.data?.status === "NEW" ? { status: "UNVERIFIED" } : {}),
      };
      mutate(data as any);

      await patchPhotos(`/retailer-profile/image`, `/retailer-profile/image`);
    }
  };

  if (isLoad) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Container>
        <Grid container spacing={0} flexDirection="column">
          <div className={classes.titleWrapper}>
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <h1>Create Profile</h1>
            </Grid>
          </div>
          <Grid display="flex">
            <form
              className={classes.form}
              onSubmit={handleSubmit((data) => handleClick(data))}
            >
              <Grid container>
                <Grid item xs={12} className={classes.item}>
                  <ModalInput
                    label="Add the link which represents you"
                    placeholder="Link from Google, Yelp"
                    className={classNames({ required: errors?.url })}
                    {...register("url", {
                      pattern: /yelp.|google/i,
                    })}
                    onChange={(event: any) => {
                      if (event.target.value.match(/yelp/i)) {
                        debounceValue(
                          event.target.value,
                          setFormValue,
                          clearErrors
                        );
                      }
                      setFormValue("url", event.target.value);
                      clearErrors("url");
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.item}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ModalInput
                        {...field}
                        label="Merchants name"
                        placeholder="Enter Merchants name"
                        error={!!errors?.name}
                        helperText={
                          (errors?.name as any)?.type
                            ? "Merchants name is Required"
                            : ""
                        }
                        className={classNames({ required: errors?.name })}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.item}>
                  <AddressAutocomplete
                    setFormValue={setFormValue}
                    control={control}
                    errors={errors}
                    modalProps={{}}
                    clearErrors={clearErrors}
                  />
                </Grid>
                <Grid item xs={4} className={classes.itemPhoto}>
                  <DragAndDrop
                    name="profile-photo"
                    label="Profile photo   1080x1080"
                    buttonLabel="Add profile photo"
                    type="PROFILE"
                    images={currentFiles}
                    uploadPhoto={updatePhoto}
                  />
                </Grid>
                <Grid item xs={4} className={classes.itemPhoto}>
                  <DragAndDrop
                    name="profile-photo-logo"
                    label="Logo photo   1920x1080"
                    buttonLabel="Add logo photo"
                    type="LOGO_LARGE"
                    images={currentFiles}
                    uploadPhoto={updatePhoto}
                  />
                </Grid>
                <Grid item xs={4} className={classes.itemPhoto}>
                  <DragAndDrop
                    name="profile-photo-interior"
                    label="Interior photo   1080x1080"
                    buttonLabel="Add interior photo"
                    type="INTERIOR"
                    images={currentFiles}
                    uploadPhoto={updatePhoto}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !isEmpty(errors)}
                  type="submit"
                >
                  Create Profile
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default CreateProfile;
