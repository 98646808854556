import React from "react";
import { makeStyles } from "@mui/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
  },
  input: {
    marginBottom: "15px",
    background: "#1A162C",
    border: "1px solid #342D5C",
    borderRadius: "5px",
    "& .MuiInputLabel-root": {
      color: "#7477A0",
    },
    "& .MuiFilledInput-input": {
      color: "#7477A0",
    },
  },
});

const LoginInput = ({ errorMessage, ...rest }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <TextField className={classes.input} {...rest} />
      {errorMessage ? <span>{errorMessage}</span> : null}
    </div>
  );
};

export default LoginInput;
