import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useMutation } from "react-query";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import { ModalInput, CustomSelect } from "components/inputs";
import { EMAIL_REGEXP } from "../../constants";

const useStyles = makeStyles({
  contentWrapper: {
    padding: "20px",
    width: "400px",
  },
  select: {
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  blockSuccess: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    "& > button": {
      margin: "0 10px",
      minWidth: "90px",
    },
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
    margin: 0,
  },
});

interface Props {
  handleClose: any;
  modalProps?: any;
}

export const redeemPrize = (data: any): Promise<any> =>
  axios.post(`/retailer/user/invite`, data).then(({ data }) => {
    return data;
  });

const InviteEmployees = ({ handleClose }: Props) => {
  const classes = useStyles();
  const [isTextSown, setTextShow] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const { mutate, isLoading, error } = useMutation(
    (value: any) => redeemPrize(value),
    {
      onSuccess: () => {
        setTextShow(true);
      },
      onError: () => {
        setRequestError(true);
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      userRole: "",
    },
  });

  const formData = watch();

  useEffect(() => {
    console.log("useEffect");
    setRequestError(false);
  }, [formData.email, formData.userRole]);

  const handleClick = (data: any) => {
    mutate({ ...data, userRole: JSON.parse(data.userRole).id });
  };

  return (
    <div className={classes.contentWrapper}>
      {isTextSown ? (
        <div className={classes.blockSuccess}>
          <p>Thanks, your invite has been sent</p>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            Ok
          </Button>
        </div>
      ) : (
        <form className={classes.form}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Invite employee
          </Typography>
          <p>Please enter employee email and choose role</p>
          <div className={classes.content}>
            <ModalInput
              size="small"
              type="text"
              label="Enter your email"
              {...register("email", {
                required: true,
                pattern: EMAIL_REGEXP,
              })}
              className={classNames({ required: errors?.email })}
              error={!!errors?.email}
              helperText={
                (errors?.email as any)?.type ? "Email is Required" : ""
              }
            />
            <CustomSelect
              {...control}
              options={[
                { id: "CAPTAIN", name: "Captain" },
                { id: "OFFICER", name: "Officer" },
                { id: "CREW_MEMBER", name: "Crew Member" },
              ]}
              errorMessage="User Role is Required"
              label="Choose Role"
              selectLabel="Choose Role"
              placeholder="Choose Role"
              name="userRole"
              size="small"
              multiple={false}
              errors={errors}
              className={classNames([classes.select], {
                required: errors?.userRole,
              })}
            />
          </div>
          {requestError && (
            <p className={classes.errorMessage}>
              {(error as any)?.response?.data?.code === "error.email-is-used"
                ? "This email is already used"
                : "Request Error"}
            </p>
          )}
          <div className={classes.buttonWrapper}>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={isLoading || !isEmpty(errors) || !isDirty}
              type="submit"
              onClick={handleSubmit(handleClick)}
            >
              Invite
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
export default InviteEmployees;
