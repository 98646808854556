import React, { useContext } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import moment from "moment";

import FullScreenLoader from "components/FullScreenLoader";
import Modal from "components/modals/Modal";
import Dashboard from "components/Dashboard";
import useResize from "hooks/useResize";
import { handleResizeTable } from "helpers/resize";
import { ROUTES, MODALS } from "../../constants";
import useModals from "hooks/useModals";
import userContext from "contexts/userContext";

const getColumns = (classes: any): GridColDef[] => {
  return [
    {
      field: "prizeId",
      headerName: "id",
      width: 100,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      editable: true,
    },
    {
      field: "prizeCount",
      headerName: "Prize Count",
      flex: 1,
      editable: true,
    },
    {
      field: "expiredAt",
      headerName: "Expired date",
      type: "string",
      width: 200,
      editable: true,
      renderCell: (value: any) => {
        return moment(value.formattedValue).format("YYYY-MM-DD hh:mm A");
      },
    },
    {
      field: "images",
      headerName: "Image",
      flex: 1,
      sortable: false,
      renderCell: ({ row }: { row: any }) => {
        const imageUrl = row?.images?.[0]?.url;
        return imageUrl ? (
          <img className={classes.image} src={imageUrl} alt="" />
        ) : null;
      },
    },
  ];
};

const useStyles = makeStyles({
  titleWrapper: {
    paddingBottom: "20px",
  },
  dataTableWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&>span": {
      fontSize: "30px",
    },
    "& .MuiDataGrid-root": {
      color: "#7B7B8B",
    },
    "& .MuiTablePagination-toolbar": {
      color: "white",
    },
    "& .MuiDataGrid-row": {
      cursor: "pointer",
    },
    "& .MuiIconButton-root": {
      "&.Mui-disabled": {
        color: "white",
        opacity: 0.2,
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      "& button": {
        color: "white",
      },
    },
    "& .MuiDataGrid-menuIcon": {
      "& button": {
        color: "white",
      },
    },
  },
  image: {
    width: "160px",
    height: "49px",
    objectFit: "contain",
    marginTop: "1px",
  },
});

export const getCampaignList = (): Promise<any> =>
  axios
    .get(`/retailer-prize/prize`)
    .then((res) =>
      res.data.filter((item: any) => item.prizeType === "REGULAR")
    );

const PrizeList = () => {
  const heightRef = React.useRef(null);
  const classes = useStyles();
  const size = useResize();
  const { user } = useContext(userContext);
  const navigate = useNavigate();
  const rowCount = handleResizeTable(size[0], size[1], heightRef?.current);
  const { data, isLoading } = useQuery(
    "сampaignsList",
    () => getCampaignList(),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 60 * 1000 * 5,
    }
  );

  const { open, handleOpen, handleClose } = useModals();

  if (!user?.data) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Container>
        <Grid container spacing={0} flexDirection="column">
          <div className={classes.titleWrapper} ref={heightRef}>
            <Dashboard />
            <h1>Prize List</h1>
            <Grid display="flex" justifyContent="right">
              <Button
                onClick={() => handleOpen(MODALS.ADD_CAMPAIGN)}
                variant="contained"
                color="primary"
                endIcon={<DynamicFeedIcon />}
              >
                Add new Prize
              </Button>
            </Grid>
          </div>
          <div className={classes.dataTableWrapper}>
            {(data || []).length ? (
              <DataGrid
                rows={data || []}
                onRowClick={({ id }) => {
                  navigate(`/${ROUTES.prizeList}/${id}`);
                }}
                loading={isLoading}
                columns={getColumns(classes)}
                pageSize={rowCount}
                rowsPerPageOptions={[data.length]}
                disableSelectionOnClick
                autoHeight
                getRowId={(r) => r.prizeId}
                isCellEditable={() => false}
              />
            ) : (
              <span>There is no prize yet. Create a new one</span>
            )}
          </div>
        </Grid>
      </Container>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.ADD_CAMPAIGN}
      />
    </>
  );
};
export default PrizeList;
