import * as React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
// import NotificationsIcon from "@mui/icons-material/Notifications";

import useModals from "hooks/useModals";
import Modal from "components/modals/Modal";
import NotificationSide from "components/NotificationSide";
import { removeToken } from "helpers/auth";
import { ROUTES, MODALS } from "../../constants";

const CustomLink = styled(Link)(({ theme }) => ({
  color: "unset",
  cursor: "unset",
  textDecoration: "unset",
}));

const pages = [
  { path: ROUTES.prizeList, name: "Prize list" },
  { path: ROUTES.grandPrizeList, name: "Grand Prize list" },
  { path: ROUTES.offer, name: "Offer list" },
];

const useStyles = makeStyles({
  appHeader: {
    background: "#19172C",
  },
  notification: {
    marginBottom: "25px",
    "& svg": {
      color: "white",
    },
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  redeemBtn: {
    margin: "0 30px 0 auto",
  },
  buttonLink: {
    "&.MuiButton-root": {
      "&.active": {
        borderBottom: "1px solid",
      },
    },
  },
});

interface Props {
  setUserData: (state: any) => void;
}

export const getNotificationCount = (): Promise<any> =>
  axios
    .get(`/notification/customer/notification/badge-count`)
    .then((res) => res.data);

const Header = ({ setUserData }: Props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [isOpen, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  // const { data } = useQuery("notifCount", () => getNotificationCount(), {
  //   refetchOnWindowFocus: false,
  // });

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    setUserData(null);
    removeToken();
  };

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(!isOpen);
    };

  const { open, handleOpen, handleClose } = useModals();

  return (
    <>
      <AppBar className={classes.appHeader} position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters className={classes.headerWrapper}>
            <Box>
              {pages.map(({ path, name }) => (
                <CustomLink key={path} to={path}>
                  <Button
                    className={classnames([classes.buttonLink], {
                      active: pathname.includes(path),
                    })}
                    onClick={handleCloseNavMenu}
                    color="info"
                  >
                    {name}
                  </Button>
                </CustomLink>
              ))}
            </Box>
            <Button
              onClick={() => handleOpen(MODALS.CHECK_REDEEM)}
              variant="contained"
              color="primary"
              className={classes.redeemBtn}
            >
              Redeem the prize
            </Button>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    handleOpen(MODALS.INVITE_EMPLOYEES);
                  }}
                >
                  <Typography textAlign="center">Invite employees</Typography>
                </MenuItem>

                <CustomLink onClick={handleCloseUserMenu} to={ROUTES.profile}>
                  <MenuItem>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                </CustomLink>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    handleLogout();
                  }}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
            {/* <Badge
              color="secondary"
              badgeContent={data?.aps?.badge || 0}
              variant="dot"
            >
              <IconButton
                className={classes.notification}
                onClick={() => setOpen(true)}
                sx={{ p: 0 }}
              >
                <NotificationsIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Badge> */}
          </Toolbar>
        </Container>
      </AppBar>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.CHECK_REDEEM}
      />
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.INVITE_EMPLOYEES}
      />
      {/* <NotificationSide isOpen={isOpen} toggleDrawer={toggleDrawer} /> */}
    </>
  );
};
export default Header;
