import axios, { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { UserInfo, Tokens } from "models/user";
import { set } from "helpers/storage";
import { USER_TOKEN } from "../constants";
import { removeToken } from "helpers/auth";

declare module "axios" {
  export interface HeadersDefaults {
    "Auth-Token"?: string;
    "Refresh-Token"?: string;
  }
}

let currentRefreshRequest: Promise<Tokens | void>;
let isTokenRefreshing: boolean = false;
let lastUsedRefreshToken: string;

export const refreshTokenRequest = async (
  refreshTokenUrl: string,
  token: string
): Promise<Tokens> => {
  axios.defaults.headers["Refresh-Token"] = token;
  const {
    data: { authToken, refreshToken },
  } = await axios.get<Tokens>(refreshTokenUrl);
  await delete axios.defaults.headers["Refresh-Token"];
  return {
    authToken,
    refreshToken,
  };
};

const refreshTokenWithResend = async (
  error: AxiosError,
  refreshTokenUrl: string,
  helpers: {
    updateTokens: (tokens: Tokens) => void;
    refreshToken?: string;
    logout: () => void;
  }
) => {
  const { refreshToken = "", updateTokens, logout } = helpers;

  if (!isTokenRefreshing && refreshToken !== lastUsedRefreshToken) {
    delete axios.defaults.headers["Auth-Token"];
    isTokenRefreshing = true;
    lastUsedRefreshToken = refreshToken;
    currentRefreshRequest = refreshTokenRequest(refreshTokenUrl, refreshToken)
      .then((res: Tokens) => {
        if (res.authToken) {
          updateTokens(res);
          return res;
        }
        throw new Error("Refresh token failed");
      })
      .catch(logout)
      .finally(() => {
        isTokenRefreshing = false;
      });
  }

  const tokens = await currentRefreshRequest;

  if (tokens && tokens.authToken) {
    error!.config!.headers!["Auth-Token"] = `${tokens.authToken}`;
    return axios(error.config);
  }

  return Promise.reject(error);
};

const useAccessRejectedInterceptor = (
  user: UserInfo | null,
  setData: (data: UserInfo | null) => void,
  refreshTokenUrl?: string
) => {
  const updateTokens = useCallback(
    (tokens: Tokens) => {
      setData({
        ...user,
        ...tokens,
      });
      set(USER_TOKEN, tokens);
    },
    [user, setData]
  );

  const logout = useCallback(() => {
    removeToken();
    setData(null);
  }, [setData]);

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const isAccessRejected = error?.response?.status === 401;

        if (error?.response?.config?.url === "/retailer/public/auth/refresh") {
          logout();
        }

        if (!user) {
          return Promise.reject(error);
        }

        if (isAccessRejected && refreshTokenUrl) {
          return refreshTokenWithResend(error, refreshTokenUrl, {
            updateTokens,
            refreshToken: user?.refreshToken || "",
            logout,
          });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [logout, updateTokens, user, refreshTokenUrl]);
};

export default useAccessRejectedInterceptor;
