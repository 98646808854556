import React from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, SubmitHandler } from "react-hook-form";
import { isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FilledInput from "@mui/material/FilledInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import { ROUTES, USER_TOKEN } from "../../../constants";
import { set } from "helpers/storage";
import { Tokens } from "models/user";

export const CustomInput = styled(TextField)(() => ({
  background: "#1A162C",
  border: "1px solid #342D5C",
  marginBottom: "5px",
  borderRadius: "5px",
  "& .MuiInputLabel-root": {
    color: "#7477A0",
  },
  "& .MuiFilledInput-input": {
    color: "#7477A0",
  },
}));

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      "Please enter password that contains at least 8 letters and numbers"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])/g,
      "Please enter password that contains at least 8 letters and numbers"
    ),
  retryPassword: Yup.string()
    .required("Confirm Password is required")
    .min(
      8,
      "Please enter password that contains at least 8 letters and numbers"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])/g,
      "Please enter password that contains at least 8 letters and numbers"
    )
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

export const useStyles = makeStyles(() => ({
  customForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "350px",
    width: "100%",
  },
  title: {
    marginBottom: "10px",
  },
  input: {
    marginBottom: "15px",
  },
  resetBtn: {
    background: "transparent",
    border: 0,
    color: "white",
    margin: "20px",
    cursor: "pointer",
  },
  icon: {
    color: "#7477A0",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    fontSize: "10px",
    "& .MuiFormControl-root": {
      "&>label": {
        color: "#7477A0",
      },
    },
    "&>span": {
      marginLeft: "5px",
      color: "#fe4c17",
    },
  },
}));

export const CustomPasswordInput = styled(FilledInput)(() => ({
  background: "#1A162C",
  border: "1px solid #342D5C",
  marginBottom: "10px",
  borderRadius: "5px",
  "& .MuiFilledInput-input": {
    color: "#7477A0",
  },
}));

interface IFormInput {
  retryPassword: string;
  password: string;
}

const setPassword = (token: string, data: IFormInput): Promise<Tokens> =>
  axios
    .patch(`/retailer/public/reset-password?token=${token}`, data)
    .then(({ data }: any) => data);

const SendPassword = ({ token }: { token: string }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { mutate, isLoading, isError, error } = useMutation(
    (data: IFormInput) => setPassword(token, data),
    {
      onSuccess: (data) => {
        set(USER_TOKEN, data);
        navigate(`/${ROUTES.login}`);
      },
    }
  );

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    mutate(data);
  };

  const [showTextPassword, setTextPassword] = React.useState(true);
  const [showTextRepassword, setShowTextRepassword] = React.useState(true);

  const handleClickShowPassword = () => {
    setTextPassword(!showTextPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowRePassword = () => {
    setShowTextRepassword(!showTextRepassword);
  };

  const handleMouseDownRePassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(formSchema),
  });

  React.useEffect(() => {
    if ((error as any)?.response?.data?.code === "error.token-is-expired") {
      setError("retryPassword", {
        type: "invalidType",
        message: "Token is expired. Try Restore password again",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(error as any)?.response?.data?.code]);

  return (
    <form className={classes.customForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.wrapper}>
        <Typography className={classes.title} variant="h5">
          Restore your password
        </Typography>
        <div className={classes.wrapper}>
          <FormControl variant="filled">
            <InputLabel htmlFor="outlined-adornment-password">
              Enter new password
            </InputLabel>
            <CustomPasswordInput
              size="small"
              id="outlined-adornment-password"
              type={showTextPassword ? "password" : "text"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showTextPassword ? (
                      <VisibilityOff className={classes.icon} />
                    ) : (
                      <Visibility className={classes.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              {...register("password", {
                required: true,
              })}
            />
          </FormControl>
        </div>
        {errors?.password?.message ? (
          <span>{errors?.password?.message}</span>
        ) : null}
        {/* {isError ? <span> Invalid email and/or password</span> : null} */}
        <div className={classes.wrapper}>
          <FormControl variant="filled">
            <InputLabel htmlFor="outlined-adornment-password">
              Repeat new password
            </InputLabel>
            <CustomPasswordInput
              size="small"
              id="outlined-adornment-password"
              type={showTextRepassword ? "password" : "text"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRePassword}
                    onMouseDown={handleMouseDownRePassword}
                    edge="end"
                  >
                    {!showTextRepassword ? (
                      <VisibilityOff className={classes.icon} />
                    ) : (
                      <Visibility className={classes.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              {...register("retryPassword", {
                required: true,
              })}
            />
          </FormControl>
        </div>
        {errors?.retryPassword?.message ? (
          <span>{errors?.retryPassword?.message}</span>
        ) : null}
      </div>
      <LoadingButton
        loading={isLoading}
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isEmpty(errors) && !isValid}
      >
        Restore password
      </LoadingButton>
      <button
        className={classes.resetBtn}
        onClick={() => navigate(`/${ROUTES.login}`)}
      >
        Return to login
      </button>
    </form>
  );
};

export default SendPassword;
