import React from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty } from "lodash";
import { CustomInput, useStyles } from "../LoginPage";
import { useForm, SubmitHandler } from "react-hook-form";
import Typography from "@mui/material/Typography";

import useLogin from "hooks/useLogin";
import { LoginSteps } from "../LoginPage";
import { set } from "helpers/storage";
import { USER_TOKEN } from "../../../constants";
import { string } from "yup";
import { Tokens } from "models/user";

interface IFormInput {
  otp: string;
}

interface OtpBody {
  otp: string;
  sessionId: string;
}

const sendOtpCode = (email: string): Promise<{ sessionId: string }> =>
  axios
    .post("/retailer/public/send-otp", { email })
    .then(({ data }: any) => data);

const checkOtpCode = (data: OtpBody): Promise<any> =>
  axios.post("/retailer/public/check-otp", data).then((res) => res);

const EmailStep = ({ setCurrentStep, currentEmail, setCurrentTokens }: any) => {
  const classes = useStyles();

  const { data: otpData } = useQuery(
    "sendCode",
    () => sendOtpCode(currentEmail),
    {
      refetchOnWindowFocus: false,
      enabled: !!currentEmail,
    }
  );

  const { mutate, isLoading, isError } = useMutation(
    (data: OtpBody) => checkOtpCode(data),
    {
      onSuccess: ({ data }: { data: Tokens }) => {
        setCurrentTokens(data);
        setCurrentStep(LoginSteps.PASS_STEP);
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (otpData) {
      mutate({ ...otpData, ...data });
    }
  };

  return (
    <form className={classes.customForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.wrapper}>
        <Typography variant="body1" className={classes.title}>
          Please check your email and enter 6-digit code
        </Typography>
        <CustomInput
          variant="filled"
          size="small"
          type="text"
          label="Enter the received code"
          {...register("otp", {
            required: true,
          })}
        />
        {isError ? <span> Invalid OTP code</span> : null}
      </div>

      <LoadingButton
        loading={isLoading}
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isEmpty(errors) && !isValid}
      >
        Accept digital code
      </LoadingButton>
    </form>
  );
};

export default EmailStep;
