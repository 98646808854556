import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useMutation } from "react-query";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import moment from "moment";
import { isEmpty } from "lodash";

import { ModalInput } from "components/inputs";

const useStyles = makeStyles({
  contentWrapper: {
    padding: "20px",
    width: "400px",
    height: "auto",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  blockSuccess: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
    "& p": {
      margin: "10px 0 0 0",
      "&:first-of-type": {
        fontSize: "20px",
        fontWeight: 600,
      },
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    "& > button": {
      margin: "0 10px",
      minWidth: "90px",
    },
  },
  prizeImage: {
    width: "200px",
    height: "auto",
    padding: "25px",
  },
});

interface Props {
  handleClose: any;
  modalProps?: any;
}

interface RedeemPrizeData {
  url: string;
  name: string;
  wonAt: string;
  prizeToken: string;
}

export const checkPrize = (token: any): Promise<RedeemPrizeData> =>
  axios
    .get(`/retailer-prize/prize/item`, { params: token })
    .then(({ data }) => {
      return {
        url: data.prize.images?.find((item: any) => item?.type === "SMALL")
          ?.url,
        name: data.prize.name,
        wonAt: data.prize.wonAt,
        prizeToken: data.prizeToken,
      };
    });

export const redeemPrize = (prizeToken: any): Promise<any> =>
  axios
    .put(`/retailer-prize/prize/item/redeem`, {
      prizeToken: prizeToken,
    })
    .then((res) => {
      return res.data;
    });

const RedeemPrize = ({ handleClose }: Props) => {
  const classes = useStyles();
  const [isTextSown, setTextShow] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [prizeData, setPrizeData] = useState<RedeemPrizeData>();
  const [prizeIsRedeemed, setPrizeRedeem] = useState(false);
  const { mutate: checkPrizeMutate, isLoading: isCheckLoading } = useMutation(
    (value: any) => checkPrize(value),
    {
      onSuccess: (data) => {
        setPrizeData(data);
        setTextShow(true);
      },
      onError: () => {
        setRequestError(true);
      },
    }
  );

  const { mutate, isLoading } = useMutation(
    (value: any) => redeemPrize(value),
    {
      onSuccess: () => {
        setPrizeRedeem(true);
      },
      onError: () => {
        setRequestError(true);
      },
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      otp: "",
    },
  });
  const otpCode = watch("otp");

  useEffect(() => {
    setRequestError(false);
  }, [otpCode]);

  const handleClick = (data: any) => {
    checkPrizeMutate(data);
  };
  const handleCheckRedeem = () => {
    mutate(prizeData?.prizeToken);
  };

  return (
    <div className={classes.contentWrapper}>
      {isTextSown ? (
        <div className={classes.blockSuccess}>
          {prizeIsRedeemed && <p>The prize is redeemed</p>}
          <p>Prize name: {prizeData?.name}</p>
          <p>Won at: {moment(prizeData?.wonAt).format("YYYY-MM-DD hh:mm A")}</p>
          <img className={classes.prizeImage} src={prizeData?.url} alt="" />
        </div>
      ) : (
        <form className={classes.form}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter short code
          </Typography>
          <div className={classes.content}>
            <ModalInput
              label="Short Code"
              placeholder="Enter short code"
              size="small"
              type="number"
              className={classNames({ required: errors?.otp })}
              error={!!errors?.otp || requestError}
              helperText={
                ((errors?.otp as any) &&
                  "Code is Required and should contain 6 digits") ||
                (requestError && "Please enter correct code")
              }
              {...register("otp", {
                required: true,
                minLength: 6,
                maxLength: 6,
              })}
            />
          </div>

          <div className={classes.buttonWrapper}>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={
                isCheckLoading || isLoading || !isEmpty(errors) || !isDirty
              }
              type="submit"
              onClick={handleSubmit(handleClick)}
            >
              Send
            </Button>
          </div>
        </form>
      )}
      {prizeIsRedeemed && (
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            Ok
          </Button>
        </div>
      )}
      {isTextSown && !prizeIsRedeemed && (
        <div className={classes.buttonWrapper}>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={isCheckLoading || isLoading}
            type="submit"
            onClick={handleSubmit(handleCheckRedeem)}
          >
            Redeem prize
          </Button>
        </div>
      )}
    </div>
  );
};
export default RedeemPrize;
