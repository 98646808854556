import React from "react";
import axios, { AxiosResponse } from "axios";
import { useMutation, MutationFunction } from "react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, SubmitHandler } from "react-hook-form";
import { isEmpty } from "lodash";
import { CustomInput, useStyles } from "../LoginPage";
import useLogin from "hooks/useLogin";
import { LoginSteps } from "../LoginPage";
import { EMAIL_REGEXP } from "../../../constants";

interface RequestInterace {
  hasPassword: boolean;
  isExists: true;
}

const checkEmail = (email: string): Promise<any> =>
  axios.post("/retailer/public/check-email", { email }).then((res) => res);

const EmailStep = ({ setCurrentStep, setCurrentEmail }: any) => {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<{ email: string }>();

  const { mutate, isLoading, isError } = useMutation(
    (email: string) => checkEmail(email),
    {
      onSuccess: ({
        data: { hasPassword, isExists },
      }: {
        data: RequestInterace;
      }) => {
        if (hasPassword && isExists) {
          setCurrentStep(LoginSteps.LOGIN_STEP);
        }
        if (!isExists) {
          setError("email", { type: "wrongEmail" });
        }
        if (!hasPassword && isExists) {
          setCurrentStep(LoginSteps.CODE_STEP);
        }
      },
    }
  );

  const onSubmit = ({ email }: { email: string }) => {
    setCurrentEmail(email);
    mutate(email);
  };

  return (
    <form className={classes.customForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.wrapper}>
        <CustomInput
          variant="filled"
          size="small"
          type="text"
          label="Provide your email for login"
          placeholder="Youremail@email.com"
          {...register("email", {
            required: true,
            // pattern: EMAIL_REGEXP,
          })}
        />
        {errors?.email?.type === "pattern" ? (
          <span>Enter valid email</span>
        ) : null}
        {errors?.email?.type === "wrongEmail" ? (
          <span>Invalid email</span>
        ) : null}
        {isError ? <span> Invalid email</span> : null}
      </div>
      {/* <div className={classes.wrapper}>
        <CustomInput
          variant="filled"
          size="small"
          type="password"
          label="Password"
          {...register("password", {
            required: true,
          })}
        />
      </div> */}
      <LoadingButton
        loading={isLoading}
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isEmpty(errors) && !isValid}
      >
        Login
      </LoadingButton>
    </form>
  );
};

export default EmailStep;
