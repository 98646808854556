import React, { useEffect } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

import { ModalInput, CustomSelect } from "components/inputs";
import DragAndDropGP from "components/DragAndDropGP";
import useFileUploadGP from "hooks/useFileUploadGP";
import CSVReader from "components/CSVReader";
import { ROUTES } from "../../constants";
import { useForceUpdate } from "hooks/useForceUpdate";

const useStyles = makeStyles({
  textFieled: {
    "& textarea": {
      color: "#7477a0",
    },
  },
  box: {
    padding: "25px 10px 10px",
    border: "1px solid #423d58",
    borderRadius: "5px",
    position: "relative",
    marginBottom: "20px",
  },
  form: {
    marginTop: "35px",
    "& .MuiFormLabel-asterisk": {
      color: "#d32f2f",
    },
  },
  item: {
    padding: "0 5px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  label: {
    background: "#201a3e",
    top: "-15px",
    left: "12px",
    color: "#ffffff",
    position: "absolute",
    padding: "5px",
  },
  photoBlock: {
    "& .MuiFormControl-root": {
      margin: "0px 0 10px 0",
      "& .MuiInputLabel-root": {
        top: "-9px",
        left: "15px",
      },
    },
    "& #input-prize-photo": {
      height: "125px",
    },
  },
  buttonWrapper: {
    marginBottom: "30px",
    display: "flex",
    justifyContent: "end",
  },
  radioButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
});
const getDate = (day: number, curentDate: Date | null) => {
  const date = curentDate ? new Date(curentDate) : new Date();
  return new Date(date.setDate(date.getDate() + day));
};

const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const saveGrandPrize = (value: any): Promise<any> =>
  axios.post(`/retailer-grand-prize/prize`, value).then((res) => res);

const CreateGrandPrize = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const [dateFrom, setDateFrom] = React.useState<Date | null>(new Date());
  const [dateTo, setDateTo] = React.useState<Date | null>(getTomorrow());
  const { currentFiles, updatePhoto, patchPhotos, onChangeDescription } =
    useFileUploadGP();

  const {
    currentFiles: currentRegularFiles,
    updatePhoto: updateRegularPhoto,
    patchPhotos: patchRegularPhotos,
    onChangeDescription: onChangeRegularDescription,
  } = useFileUploadGP();

  const [isLoadingSend, setLoading] = React.useState(false);
  const [codeValue, setCodeValue] = React.useState("");
  const [codeError, setCodeError] = React.useState(null);
  const [categoriesOptions, setCategoriesOptions] = React.useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    setError,
    watch,
    resetField,
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      prizeType: "QR_CODE",
      redemptionCodes: "",
      terms: "",
      redemptionUrl: "",
      businessWebsite: "",
      shippingTime: "",
      endDate: new Date(),
      startDate: new Date(),
      description: "",
      businessEmail: "",
      name: "",
      price: 1,
      prizeCount: 1,
      purchaseUrl: "",
      discountCode: "",
      categories: [],
    },
  });

  React.useEffect(() => {
    axios.get(`/retailer-prize/category`).then(({ data }) => {
      setCategoriesOptions(data);
    });
  }, []);

  const formValues = watch();
  useEffect(() => {
    if (error) {
      setCodeError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.redemptionCodes]);

  const { mutate, isLoading, error } = useMutation((value: any) =>
    saveGrandPrize(value)
  );

  useEffect(() => {
    if (codeValue) {
      setValue("redemptionCodes", codeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeValue]);

  useEffect(() => {
    resetField("redemptionUrl");
    resetField("redemptionCodes");
    resetField("businessWebsite");
    resetField("businessEmail");
    resetField("shippingTime");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.prizeType]);

  useEffect(() => {
    if ((error as any)?.response?.data?.code) {
      setCodeError((error as any)?.response?.data?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleClick = async (data: any) => {
    setLoading(true);
    await mutate(
      {
        ...data,
        redemptionCodes: data?.redemptionCodes
          ? data.redemptionCodes.split(",").map((item: string) => item.trim())
          : undefined,
        categories: data.categories.map((item: string) => JSON.parse(item)),
        prizeCount: Number(data.prizeCount),
        price: Number(data.price),
      },
      {
        onSuccess: async ({ data }: any) => {
          await patchPhotos(
            `/retailer-grand-prize/prize/${data?.prizeId}/media`
          );
          await patchRegularPhotos(
            `/retailer-grand-prize/prize/${data?.prizeId}/media`
          );

          setTimeout(() => {
            queryClient.invalidateQueries("grandPrizeList");
            setLoading(false);
            navigate(`/${ROUTES.grandPrizeList}`);
          }, 3000);
        },
      }
    );
  };

  const isMainPhotoValid =
    (currentFiles.length === 1 &&
      currentFiles.every((file) => file?.isCropped)) ||
    (currentFiles.length === 1 && currentFiles[0].type === "VIDEO");

  const isAdditionalPhotosValid =
    currentRegularFiles.length > 2 &&
    currentRegularFiles.length < 9 &&
    currentRegularFiles.every((file) => file?.isCropped);

  const isPhotoDescriptionValid =
    [...currentFiles, ...currentRegularFiles].find(
      (item) => item.description.length > 124 || item.description.length === 0
    ) === undefined;

  const datePlus90 = new Date();
  datePlus90.setDate(datePlus90.getDate() + 90);

  const getPlus180Days = (date: Date) => {
    const datePlus180 = new Date(date);
    datePlus180.setDate(datePlus180.getDate() + 180);
    return datePlus180;
  };

  const getContent = (value: any) => {
    switch (value) {
      case "REDEMPTION_CODE": {
        return (
          <Grid container>
            <Grid item xs={12} className={classes.item}>
              <Controller
                name="redemptionUrl"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    required
                    size="small"
                    label="Input your business website address"
                    placeholder="www.swiggle.com"
                    className={classNames({
                      required: errors?.redemptionUrl,
                    })}
                    error={!!errors?.redemptionUrl}
                    helperText={
                      (errors?.redemptionUrl as any)?.type
                        ? "Url is Required"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <Controller
                name="redemptionCodes"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    required
                    size="small"
                    label="Enter a generic redemption code"
                    placeholder="Input your generic redemption code"
                    multiline
                    rows={4}
                    className={classNames([classes.textFieled], {
                      required: errors?.redemptionCodes,
                    })}
                    error={codeError || !!errors?.redemptionCodes}
                    helperText={
                      (errors?.redemptionCodes as any)?.type
                        ? "RedemptionCodes are Required"
                        : codeError
                        ? `Set please ${formValues?.prizeCount} codes`
                        : ""
                    }
                  />
                )}
              />
              <p>
                ** # of codes needs to be greater than the number of prizes
                available**
              </p>
              <p>
                Or bulk upload CSV file with a list of unique redemption codes
              </p>
              <CSVReader setCodeValue={setCodeValue} />
            </Grid>
          </Grid>
        );
      }
      case "SHIP_TO_ADDRESS": {
        return (
          <Grid container>
            <Grid item xs={12} className={classes.item}>
              <Controller
                name="businessWebsite"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    label="Input your business website address"
                    placeholder="www.swiggle.com"
                    required
                    className={classNames({
                      required: errors?.businessWebsite,
                    })}
                    size="small"
                    error={!!errors?.businessWebsite}
                    helperText={
                      (errors?.businessWebsite as any)?.type
                        ? "Website is Required"
                        : ""
                    }
                  />
                )}
              />
              <Controller
                name="businessEmail"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    required
                    label="Enter customer service email address"
                    placeholder="info@swiggle.com"
                    size="small"
                    className={classNames({ required: errors?.businessEmail })}
                    error={!!errors?.businessEmail}
                    helperText={
                      (errors?.businessEmail as any)?.type
                        ? "Email is Required"
                        : ""
                    }
                  />
                )}
              />
              <Controller
                name="shippingTime"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    required
                    label="Input estimated shipping time"
                    placeholder="Ex. 3-4 weeks"
                    size="small"
                    className={classNames({ required: errors?.shippingTime })}
                    error={!!errors?.shippingTime}
                    helperText={
                      (errors?.shippingTime as any)?.type
                        ? "Shipping Time is Required"
                        : ""
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      }
      default:
        return null;
    }
  };

  let additionalPrizeInfo = getContent(formValues?.prizeType);

  return (
    <Container>
      <form className={classes.form}>
        <Box className={classes.box}>
          <span className={classes.label}>Main Grand Prize Information</span>
          <Grid container>
            <Grid item xs={8} className={classes.item}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true, minLength: 1, maxLength: 100 }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    size="small"
                    required
                    label="Grand prize Headline"
                    placeholder="Name or short description of GP"
                    className={classNames({ required: errors?.name })}
                    error={!!errors?.name}
                    helperText={
                      (errors?.name as any)?.type
                        ? (errors?.name as any)?.type === "maxLength"
                          ? "Grand prize Headline shouldn't contain more than 100 letters"
                          : "Grand prize Headline is Required"
                        : ""
                    }
                  />
                )}
              />
              {/* register your input into the hook by invoking the "register" function */}
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Controller
                name="prizeCount"
                control={control}
                rules={{
                  required: true,
                  min: 1,
                  max: 900,
                }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    size="small"
                    required
                    type="number"
                    label="How many prizes are available"
                    placeholder="How many prizes"
                    className={classNames({ required: errors?.prizeCount })}
                    error={!!errors?.prizeCount}
                    helperText={
                      (errors?.prizeCount as any)?.type
                        ? (errors?.prizeCount as any)?.type === "max"
                          ? "Count shouldn't be more than 900"
                          : "Count is Required and should be more then 0"
                        : ""
                    }
                  />
                )}
              />
              {/* register your input into the hook by invoking the "register" function */}
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Controller
                name="price"
                control={control}
                rules={{
                  required: true,
                  min: 1,
                  max: 4999,
                }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    type="number"
                    size="small"
                    required
                    label="Retail value of GP, $"
                    placeholder="Enter Value of Grand prize"
                    className={classNames({ required: errors?.price })}
                    error={!!errors?.price}
                    helperText={
                      (errors?.price as any)?.type
                        ? (errors?.price as any)?.type === "max"
                          ? "Price shouldn't be more than 5000"
                          : "Price is Required and should be more then 0"
                        : ""
                    }
                  />
                )}
              />
              {/* register your input into the hook by invoking the "register" function */}
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disablePast
                  maxDate={datePlus90}
                  renderInput={(props: any) => (
                    <ModalInput
                      className={classNames({ required: errors?.startDate })}
                      {...props}
                      {...register("startDate", { valueAsDate: true })}
                      size="small"
                      required
                    />
                  )}
                  value={dateFrom}
                  onChange={(newDate) => {
                    if (newDate) {
                      if (
                        new Date().getMonth() >= newDate.getMonth() &&
                        new Date().getDate() > newDate.getDate()
                      ) {
                        setError("startDate", { type: "invalidTime" });
                      } else {
                        resetField("startDate");
                      }
                      setValue("startDate", newDate ?? new Date());
                      setDateFrom(newDate);
                    }
                  }}
                  label="Start date of Grand Prize"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  minDate={getDate(1, dateFrom) || new Date()}
                  maxDate={getPlus180Days(dateFrom || new Date())}
                  renderInput={(props: any) => (
                    <ModalInput
                      className={classNames({ required: errors?.endDate })}
                      {...props}
                      {...register("endDate", { valueAsDate: true })}
                      size="small"
                      required
                    />
                  )}
                  value={dateTo}
                  onChange={(newDate) => {
                    if (newDate) {
                      setValue("endDate", newDate ?? new Date());
                      setDateTo(newDate);
                    }
                  }}
                  label="End date of Grand Prize"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <CustomSelect
                {...control}
                options={categoriesOptions}
                errors={errors}
                size="small"
                required
                className={classNames({ required: errors?.categories })}
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Controller
                name="purchaseUrl"
                control={control}
                rules={{
                  required: !!formValues.discountCode,
                }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    size="small"
                    required={!!formValues.discountCode}
                    label="Link to the product for purchase"
                    placeholder="please add a link to purchase on your website"
                    className={classNames({ required: errors?.purchaseUrl })}
                    error={!!errors?.purchaseUrl}
                    helperText={
                      (errors?.purchaseUrl as any)?.type && "Link is Required"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Controller
                name="discountCode"
                control={control}
                rules={{
                  required: !!formValues.purchaseUrl,
                  minLength: 4,
                  maxLength: 12,
                }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    required={!!formValues.purchaseUrl}
                    size="small"
                    label="Referral code"
                    placeholder="Provide your referral code for a discount"
                    className={classNames({ required: errors?.discountCode })}
                    error={!!errors?.discountCode}
                    helperText={
                      (errors?.discountCode as any)?.type
                        ? (errors?.discountCode as any)?.type === "maxLength"
                          ? "Referral code shouldn't contain more than 12 letters"
                          : "Referral code is Required"
                        : ""
                    }
                  />
                )}
              />
              {/* register your input into the hook by invoking the "register" function */}
            </Grid>

            <Grid item xs={12} className={classes.item}>
              <Controller
                name="description"
                control={control}
                rules={{ required: true, maxLength: 511 }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    size="small"
                    required
                    label="Enter description"
                    placeholder="list any restrictions on when a user can redeem this prize"
                    multiline
                    rows={4}
                    error={!!errors?.description}
                    helperText={
                      (errors?.description as any)?.type
                        ? (errors?.description as any)?.type === "maxLength"
                          ? "Description shouldn't contain more than 511 letters"
                          : "Description is Required"
                        : ""
                    }
                    className={classNames([classes.textFieled], {
                      required: errors?.description,
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <Controller
                name="terms"
                control={control}
                rules={{ required: true, maxLength: 1500 }}
                render={({ field }) => (
                  <ModalInput
                    {...field}
                    size="small"
                    required
                    label="Enter terms and conditions"
                    placeholder="list any restrictions on when a user can redeem this prize"
                    multiline
                    rows={4}
                    error={!!errors?.terms}
                    helperText={
                      (errors?.terms as any)?.type
                        ? (errors?.terms as any)?.type === "maxLength"
                          ? "Terms and conditions shouldn't contain more than 1500 letters"
                          : "Terms and conditions field is Required"
                        : ""
                    }
                    className={classNames([classes.textFieled], {
                      required: errors?.terms,
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <DragAndDropGP
                name="prize-photo"
                label="Main GP photo/video 1320 x 1972"
                required
                type="MAIN"
                images={currentFiles}
                uploadPhoto={updatePhoto}
                accept={[
                  "image/png",
                  "image/jpeg",
                  "video/mp4",
                  "video/MP4",
                  "video/mov",
                  "video/MOV",
                  "video/quicktime",
                  "video/QUICKTIME",
                ]}
                onChangeDescription={onChangeDescription}
                forceUpdate={forceUpdate}
              />
            </Grid>
            <Grid item xs={12} className={classes.item}>
              <DragAndDropGP
                name="prize-photo1"
                required
                label="Additional GP Photos (min 3 photos) 1080x1080"
                type="REGULAR"
                images={currentRegularFiles}
                uploadPhoto={updateRegularPhoto}
                isMultiple
                accept={["image/png", "image/jpeg"]}
                onChangeDescription={onChangeRegularDescription}
                forceUpdate={forceUpdate}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.box}>
          <span className={classes.label}>Choose Prize Type</span>
          <Grid container>
            <Grid item xs={12} className={classes.item}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  className={classes.radioButtons}
                >
                  <Controller
                    name="prizeType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        value="QR_CODE"
                        control={
                          <Radio
                            checked={formValues?.prizeType === "QR_CODE"}
                          />
                        }
                        label="QR code"
                      />
                    )}
                  />
                  <Controller
                    name="prizeType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        value="REDEMPTION_CODE"
                        control={
                          <Radio
                            checked={
                              formValues?.prizeType === "REDEMPTION_CODE"
                            }
                          />
                        }
                        label="Redemption code"
                      />
                    )}
                  />
                  <Controller
                    name="prizeType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        value="SHIP_TO_ADDRESS"
                        control={
                          <Radio
                            checked={
                              formValues?.prizeType === "SHIP_TO_ADDRESS"
                            }
                          />
                        }
                        label="Grand prize will be shipped to customer"
                      />
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {additionalPrizeInfo && (
          <Box className={classes.box}>
            <span className={classes.label}>
              Additional Grand Prize Information
            </span>
            {additionalPrizeInfo}
          </Box>
        )}

        <Grid container className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleClick)}
            disabled={
              isLoading ||
              !isEmpty(errors) ||
              !isDirty ||
              !isMainPhotoValid ||
              !isAdditionalPhotosValid ||
              !isPhotoDescriptionValid ||
              isLoadingSend
            }
          >
            Create the prize
          </Button>
        </Grid>
      </form>
    </Container>
  );
};
export default CreateGrandPrize;
