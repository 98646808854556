import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";
import FullScreenLoader from "components/FullScreenLoader";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import PrizeListDetails from "./pages/PrizeListDetails";
import OfferDetails from "./pages/OfferDetails";
import PrizeList from "./pages/PrizeList";
import CreateGrandPrize from "./pages/CreateGrandPrize";
import GrandPrizeList from "./pages/GrandPrizeList";
import OfferList from "./pages/OfferList";
import Profile from "./pages/Profile";
import CreateProfile from "./pages/CreateProfile";
import ResetPassword from "./pages/ResetPassword";
import { get } from "helpers/storage";
import { USER_TOKEN, ROUTES } from "./constants";
import useAccessRejectedInterceptor from "hooks/useAccessRejectedInterceptor";
import useAuthorizationHeader from "hooks/useAuthorizationHeader";
import useUserData from "hooks/useUserData";
import { UserInfo } from "models/user";
import UserContext from "contexts/userContext";

import "./App.scss";
import { isEmpty } from "lodash";

function App() {
  const [userData, setUserData] = useState<UserInfo | null>(get(USER_TOKEN));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoggedIn, loading] = useAuthorizationHeader(userData?.authToken);

  const isUserLoading: boolean = useUserData(userData, setUserData);

  useAccessRejectedInterceptor(
    userData,
    setUserData,
    "/retailer/public/auth/refresh"
  );

  useEffect(() => {
    setUserData(get(USER_TOKEN));
    if (pathname === "/") {
      navigate(`/${ROUTES.login}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  // useEffect(() => {
  //   if (!userData?.authToken) {
  //     console.log("remove token");
  //     removeToken();
  //     navigate(`/${ROUTES.login}`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData]);

  const hasUser =
    !!userData?.data &&
    (userData?.data?.name !== "" || userData?.data?.newData?.name !== "");

  const emptyUser =
    userData?.data?.name === "" ||
    userData?.data?.newData?.name === "" ||
    userData?.data?.status === "NEW";

  if (isLoggedIn === null || loading || isUserLoading) {
    return <FullScreenLoader />;
  }
  return (
    <UserContext.Provider
      value={{
        user: userData,
        setData: setUserData,
      }}
    >
      <div className="App">
        <>
          <Routes>
            <Route
              path={ROUTES.login}
              element={<LoginPage isAuth={isLoggedIn} />}
            />
            <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
            <Route
              element={
                <ProtectedRoute
                  isAuth={isLoggedIn && emptyUser && hasUser}
                  setUserData={setUserData}
                  redirectPath={`/${ROUTES.prizeList}`}
                />
              }
            >
              <Route path={ROUTES.createProfile} element={<CreateProfile />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  isAuth={isLoggedIn && !emptyUser && hasUser}
                  setUserData={setUserData}
                />
              }
            >
              <Route
                path={ROUTES.createGrandPrize}
                element={<CreateGrandPrize />}
              />
              <Route path={ROUTES.prizeList} element={<PrizeList />} />
              <Route
                path={ROUTES.grandPrizeList}
                element={<GrandPrizeList />}
              />
              <Route path={ROUTES.offer} element={<OfferList />} />
              <Route
                path={`${ROUTES.prizeList}/:id`}
                element={<PrizeListDetails />}
              />
              <Route path={`${ROUTES.offer}/:id`} element={<OfferDetails />} />
              <Route path={ROUTES.profile} element={<Profile />} />
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </>
      </div>
    </UserContext.Provider>
  );
}

export default App;
