import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CropIcon from "@mui/icons-material/Crop";
import useModals from "hooks/useModals";
import { MODALS } from "../../constants";
import Modal from "components/modals/Modal";
import { updatePhotoSize, getImageSize } from "helpers/updatePhotoSize";

const useStyles = makeStyles({
  dragAndDrop: {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputWrapper: {
    "&.MuiFormControl-root": {
      margin: "40px 0 10px 0",
      "& .MuiInputLabel-root": {
        transform: "translate(0, 0) scale(0.75)",
        top: "-25px",
      },
    },
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    marginLeft: 10,
    justifyContent: "center",
    position: "relative",
  },
  thumb: {
    display: "inline-flex",
    marginBottom: 18,
    marginRight: 8,
    width: "auto",
    height: 140,
    boxSizing: "border-box",
    position: "relative",
    justifyContent: "center",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  icon: {
    background: "#eb62ff33",
    borderRadius: "50%",
    position: "absolute",
    right: "-15px",
    top: "-5px",
    padding: "2px",
    cursor: "pointer",
    color: "#EB62FF",
  },
  iconLeft: {
    left: "-10px",
  },
  croppedErrror: {
    position: "absolute",
    bottom: "-28px",
    fontSize: "13px",
    color: "red",
  },
  isNotCropped: {
    border: "1px solid red",
  },
});

const Thumb = ({
  file,
  onRemove,
  updatePhoto,
  type,
}: {
  file: any;
  onRemove: any;
  updatePhoto: any;
  type?: any;
}) => {
  const url = file?.preview || file.url || "";
  const name = file.media?.name || file.name;

  const classes = useStyles();
  const { open, handleOpen, handleClose } = useModals();

  return (
    <>
      <div
        className={classNames([classes.thumb], {
          [classes.isNotCropped]: file.isCropped === false,
        })}
      >
        {file.hasOwnProperty("isCropped") && !file.isCropped && (
          <CropIcon
            onClick={() => {
              handleOpen(MODALS.CROP_IMAGE, {
                src: file?.url || URL.createObjectURL(file as Blob),
                updatePhoto: updatePhoto,
                aspect: getImageSize(type).width / getImageSize(type).height,
              });
            }}
            className={classNames([classes.iconLeft, classes.icon])}
          />
        )}
        <DeleteOutlineIcon onClick={onRemove} className={classes.icon} />
        <div className={classes.thumbInner}>
          <img
            src={url}
            className={classes.img}
            alt={name}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(url);
            }}
          />
        </div>
        {file.isCropped === false && (
          <p className={classes.croppedErrror}>Please crop the image</p>
        )}
      </div>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.CROP_IMAGE}
      />
    </>
  );
};
export default Thumb;
