import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import Button from "@mui/material/Button";
import { canvasPreview } from "helpers/canvasPreview";
import { centerAspectCrop } from "helpers/updatePhotoSize";

import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles({
  contentWrapper: {
    padding: "20px",
    height: "90%",
  },
  img: {
    width: "100%",
    height: "100%",
    maxHeight: "85vh !important",
  },
  btn: {
    margin: "0 30px 0 auto",
  },
  select: {
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  center: {
    textAlign: "center",
    paddingTop: "10px",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  blockSuccess: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    "& > button": {
      margin: "0 10px",
      minWidth: "90px",
    },
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
    margin: 0,
  },
});

interface Props {
  handleClose: any;
  modalProps?: {
    src: string;
    updatePhoto: (photo: any) => void;
    aspect?: number;
  };
}

const CropImage = ({ handleClose, modalProps }: Props) => {
  const classes = useStyles();
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = React.useState<Crop>();
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (modalProps?.aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, modalProps?.aspect));
    }
  }

  return (
    <div className={classes.contentWrapper}>
      <ReactCrop
        crop={crop}
        aspect={modalProps?.aspect || 1.77777778}
        onComplete={(c) => setCompletedCrop(c)}
        onChange={(c) => setCrop(c)}
      >
        <img
          ref={imgRef}
          id="cropImg"
          className={classes.img}
          src={modalProps?.src || ""}
          alt=""
          onLoad={onImageLoad}
        />
      </ReactCrop>
      <div className={classes.center}>
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          disabled={!crop}
          onClick={() => {
            let canvas = document.createElement("canvas");

            if (imgRef.current && completedCrop) {
              canvasPreview(imgRef.current, canvas, completedCrop);

              canvas.toBlob(function (blob) {
                if (blob) {
                  modalProps?.updatePhoto(blob);
                }
                handleClose();
              }, "image/png");
            }
          }}
        >
          Crop Photo
        </Button>
      </div>
    </div>
  );
};
export default CropImage;
