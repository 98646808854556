import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";

interface Props {
  label?: string;
  helperText?: string;
  name: string;
  type?: string;
  placeholder?: string;
  children: any;
}

const useStyles = makeStyles({
  inputWrapper: {
    "&.MuiFormControl-root": {
      margin: "5px 0",
    },
    "& .MuiInputLabel-root": {
      transform: "translate(0, 0) scale(0.75)",
    },
    "& .MuiInput-root": {
      marginTop: "20px",
      border: "1px solid black",
      borderRadius: "5px",
      padding: "2px",
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      "& :hover": {},
    },
  },
});

const CustomInput = ({ name, helperText, label, children }: Props) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.inputWrapper}>
      {label && (
        <InputLabel shrink htmlFor={`input-${name}`}>
          {label}
        </InputLabel>
      )}
      {children}
      {helperText && (
        <FormHelperText id={`helper-text-${name}`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
export default CustomInput;
