import { useState } from "react";
import axios from "axios";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const useFileUpload = (
  images: any = [],
  bigType = "BIG",
  smallType = "SMALL",
  additionalType?: string
) => {
  const [currentFiles, setCurrentFiles] = useState<any>(
    images?.reduce(
      (acc: any, item: any) => {
        if (item.type === bigType) {
          return { ...acc, [bigType]: item };
        }
        if (item.type === smallType) {
          return { ...acc, [smallType]: item };
        }
        if (item.type === additionalType) {
          return {
            ...acc,
            ...(additionalType ? { [additionalType]: item } : {}),
          };
        }
        return acc;
      },
      { [bigType]: {}, [smallType]: {} }
    )
  );

  const updatePhoto = (file: any, type: string) => {
    setCurrentFiles({ ...currentFiles, [type]: file });
  };

  const patchPhotos = async (putUrl: string, deleteUrl?: string) => {
    const promises = await Object.entries(currentFiles).reduce(
      (acc: any, item: any) => {
        const [key, value] = item;
        if (value?.preview) {
          const formData = new FormData();

          formData.append("type", key);
          formData.append("name", value.name);
          formData.append("image", value);
          return [...acc, axios.put(putUrl, formData, config)];
        }
        if (deleteUrl && value?.removed) {
          return [...acc, axios.delete(`${deleteUrl}/${value.imageId}`)];
        }

        return [...acc];
      },
      []
    );
    await Promise.all(promises);
    return true;
  };

  return { currentFiles, updatePhoto, patchPhotos };
};

export default useFileUpload;
