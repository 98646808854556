import { ROUTES } from "../../constants";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import Header from "components/Header";

const useStyles = makeStyles({
  privatWrapper: {
    height: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: 0,
  },
  contentWrapper: {
    backgroundColor: "#201A3E",
    color: "#7B7B8B",
    flex: 1,
    height: "calc(100vh - 64px)",
    overflow: "scroll",
  },
});
interface Props {
  isAuth: boolean;
  children?: React.ReactElement;
  redirectPath?: string;
  setUserData: any;
}

const ProtectedRoute = ({
  isAuth,
  redirectPath = `/${ROUTES.login}`,
  setUserData,
  children,
}: Props) => {
  const classes = useStyles();

  if (!isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div className={classes.privatWrapper}>
      <Header setUserData={setUserData} />
      <div className={classes.contentWrapper}>
        {children ? children : <Outlet />}
      </div>
    </div>
  );
};

export default ProtectedRoute;
