import axios from "axios";
import { useQuery } from "react-query";
import { set } from "helpers/storage";
import { USER_TOKEN } from "../constants";

import { UserInfo } from "models/user";

const getProfile = (): Promise<any> =>
  axios.get(`/retailer-profile/profile`).then((res) => res);

const useUserData = (userData: UserInfo | null, setUserData: any) => {
  const updateProfile =
    (!!userData?.authToken && !userData?.data?.name) ||
    userData?.data?.status === "NEW";

  const { isLoading } = useQuery("userProfile", () => getProfile(), {
    refetchOnWindowFocus: false,
    enabled: updateProfile,
    onSuccess: ({ data }) => {
      setUserData({ ...userData, data });
      set(USER_TOKEN, { ...userData, data });
    },
  });
  return isLoading;
};
export default useUserData;
