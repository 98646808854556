import { useState } from "react";
import axios from "axios";

import { MediaGP } from "models/media";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const getChangedDescription = (
  value: MediaGP,
  description: string
): MediaGP => {
  let resultValue = value;
  if (value.error && description) {
    resultValue = { ...resultValue, error: false };
  }
  if (description === "") {
    resultValue = { ...resultValue, error: true };
  }
  return { ...resultValue, description: description };
};

export const useFileUpload = (
  images: any[] = []
): {
  currentFiles: MediaGP[];
  updatePhoto: any;
  patchPhotos: any;
  onChangeDescription: any;
} => {
  const [currentFiles, setCurrentFiles] = useState<MediaGP[]>([]);

  const onChangeDescription = (id: any, description: string) => {
    setCurrentFiles(
      currentFiles.map((item) => {
        if (item?.mediaId === id)
          return getChangedDescription(item, description);
        if (item?.uuid === id) return getChangedDescription(item, description);
        return item;
      })
    );
  };

  const patchPhotos = async (url: string) => {
    const promises = currentFiles.map((item) => {
      if (url && item?.removed) {
        return axios.delete(`${url}/${item.mediaId}`);
      } else {
        const formData = new FormData();
        const fileName = item?.name || item.media?.name || "";
        const file = item?.media || "";
        formData.append("type", item?.type);
        formData.append("name", fileName);
        formData.append("media", file);
        formData.append("description", item.description);
        return [axios.put(url, formData, config)];
      }
    });
    await Promise.all(promises);
    return true;
  };

  return {
    currentFiles,
    updatePhoto: setCurrentFiles,
    patchPhotos,
    onChangeDescription,
  };
};

export default useFileUpload;
