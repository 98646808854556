import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import winSpin from "../../assets/swiggle.png";
import useQuery from "hooks/useQuery";
import { SendEmail, SendPassword } from "./block";

export const CustomInput = styled(TextField)(() => ({
  background: "#1A162C",
  border: "1px solid #342D5C",
  marginBottom: "5px",
  borderRadius: "5px",
  "& .MuiInputLabel-root": {
    color: "#7477A0",
  },
  "& .MuiFilledInput-input": {
    color: "#7477A0",
  },
}));

export const CustomWrapper = styled(Box)(() => ({
  color: "#ffffff",
  background:
    " transparent linear-gradient(337deg, #FC6CAC -50%, #201A3E 50%, #8466F4 100%)",
}));

export const useStyles = makeStyles(() => ({
  customForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "350px",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    fontSize: "10px",
    "&>span": {
      marginLeft: "5px",
      color: "#fe4c17",
    },
  },
  title: {
    marginBottom: "10px",
  },
  input: {
    marginBottom: "15px",
  },
  resetBtn: {
    background: "transparent",
    border: 0,
    color: "white",
    margin: "20px",
    cursor: "pointer",
  },
}));

interface Props {}

const ResetPassword = (props: Props) => {
  const query = useQuery();
  const resetToken = query.get("token");

  return (
    <CustomWrapper sx={{ flexGrow: 1 }}>
      <Container>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <img
            style={{ marginBottom: "80px", maxWidth: "400px" }}
            src={winSpin}
            alt="logo"
          />
          {resetToken ? <SendPassword token={resetToken} /> : <SendEmail />}
        </Grid>
      </Container>
    </CustomWrapper>
  );
};
export default ResetPassword;
