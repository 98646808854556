import React, { useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useDropzone } from "react-dropzone";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import { isEmpty } from "lodash";

import { MODALS } from "../../constants";
import Thumb from "./Thumb";
import useModals from "hooks/useModals";
import Modal from "components/modals/Modal";
import { getImageSize } from "helpers/updatePhotoSize";

const useStyles = makeStyles<{ width: number; height: number }>({
  dragAndDrop: {
    border: "1px solid rgb(52, 45, 92)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#7477A0",
    background: "0% 0% no-repeat padding-box padding-box rgb(26, 22, 44)",

    width: ({ width }: any) => `${width}px`,
    height: ({ height }: any) => `${height}px`,
  },
  inputWrapper: {
    "&.MuiFormControl-root": {
      margin: "40px 0 10px 0",
      "& .MuiInputLabel-root": {
        transform: "translate(0, 0) scale(0.75)",
        top: "-25px",
        color: "#7477A0",
      },
    },
  },
  thumbsContainer: {
    color: "#7477A0",
    border: "unset",
    cursor: "pointer",
    background: "unset",
    alignItems: "center",
    borderRadius: "5px",
    justifyContent: "center",
    paddingTop: "7px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 0,
    marginLeft: 10,
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    width: "auto",
    height: 140,
    padding: 4,
    boxSizing: "border-box",
    margin: "0 auto 8px auto",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  dndContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "15px 0 5px 0",
    "& svg": {
      color: "#EB62FF",
      background: "#eb62ff33",
      padding: "10px",
      borderRadius: "15px",
    },
  },
  errorMsg: {
    width: "100%",
    position: "absolute",
    bottom: "-22px",
    textAlign: "center",
    color: "red",
    fontSize: "12px",
  },
});

type ImageType = "SMALL" | "BIG" | "PROFILE" | "LOGO_LARGE" | "INTERIOR";

interface File {
  imageId: number;
  name: string;
  type: ImageType;
  url: string;
}

const sizes: Record<ImageType, { width: number; height: number }> = {
  BIG: {
    width: 145,
    height: 145,
  },
  SMALL: {
    width: 250,
    height: 145,
  },
  PROFILE: {
    width: 145,
    height: 145,
  },
  LOGO_LARGE: {
    width: 250,
    height: 145,
  },
  INTERIOR: {
    width: 145,
    height: 145,
  },
};

const DragAndDrop = ({
  label,
  name,
  type,
  images,
  uploadPhoto = (file: File | any, type: ImageType) => {},
  buttonLabel = "Add Prize picture",
}: {
  type: ImageType;
  label: any;
  name: any;
  images: any;
  uploadPhoto?: any;
  buttonLabel?: any;
}) => {
  const classes = useStyles({
    width: sizes[type].width,
    height: sizes[type].height,
  });

  const [errorMsg, setErrorMsg] = useState<{ [key: string]: string }>({});
  const [files, setFiles] = useState<any>(images[type] ? [images[type]] : []);

  const { open, handleOpen, handleClose } = useModals();

  const removeFile = (file: any) => {
    uploadPhoto(isEmpty(file) ? file : { ...file, removed: true }, type);
    setFiles([]);
  };

  const updateCropPhoto = (file: any, index: number) => {
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      isCropped: true,
    });
    setFiles([file]);
    uploadPhoto(file, type);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const returnPhotos = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          isCropped: false,
        })
      );
      setFiles(returnPhotos);
      const filePhoto = returnPhotos[0];
      uploadPhoto(filePhoto, type);
      setErrorMsg({
        [type]: "",
      });
      handleOpen(MODALS.CROP_IMAGE, {
        src: URL.createObjectURL(filePhoto as Blob),
        updatePhoto: updateCropPhoto,
        aspect: getImageSize(type).width / getImageSize(type).height,
      });
    },
    onDropRejected: (rejectedFiles) => {
      setFiles([{}]);
      setErrorMsg({
        [type]:
          rejectedFiles[0].errors[0].code === "file-too-large"
            ? "File is larger than 8 mb"
            : "Image load error",
      });
    },
    multiple: false,
    maxSize: 8388608,
    accept: ["image/png", "image/jpeg"],
  });
  const thumbs = files.map((file: any, index: number) => {
    if (isEmpty(file)) {
      if (!isEmpty(errorMsg)) {
        return (
          <p key={type} className={classes.errorMsg}>
            {errorMsg?.[type]}
          </p>
        );
      }
      return null;
    }
    return (
      <Thumb
        key={file?.preview || file.url}
        file={file}
        type={type}
        onRemove={() => removeFile(file?.preview ? {} : file)}
        updatePhoto={(file: any) => updateCropPhoto(file, index)}
      />
    );
  });

  return (
    <>
      <FormControl className={classes.inputWrapper}>
        {label && (
          <InputLabel shrink htmlFor={`input-${name}`}>
            {label}
          </InputLabel>
        )}
        {!thumbs.length || thumbs[0] === null ? (
          <>
            <div
              id={`input-${name}`}
              className={classes.dragAndDrop}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div className={classes.dndContent}>
                  <AddAPhotoIcon />
                  <p>{buttonLabel}</p>
                </div>
              )}
            </div>
          </>
        ) : (
          <aside className={classes.thumbsContainer}>{thumbs}</aside>
        )}
      </FormControl>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.CROP_IMAGE}
      />
    </>
  );
};

export default DragAndDrop;
