import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { toNumber, isEmpty, differenceWith, isEqual, isString } from "lodash";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import DragAndDrop from "components/DragAndDrop";
import useFileUpload from "hooks/useFileUpload";
import classNames from "classnames";
import { differenceInDays, differenceInCalendarDays } from "date-fns";
import { ModalInput, CustomSelect } from "components/inputs";

const useStyles = makeStyles({
  containerModal: {
    height: "350px",
    overflow: "scroll",
    paddingTop: "15px",
  },
  contentWrapper: {
    padding: "20px",
    width: "650px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  textFieled: {
    "& textarea": {
      color: "#7477a0",
    },
  },
  item: {
    "& > .MuiFormControl-root": {
      width: "95%",
    },
  },
  itemFullWith: {
    "& > .MuiFormControl-root": {
      width: "97.5%",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    "& > button": {
      margin: "0 10px",
      minWidth: "90px",
    },
  },
  itemPhoto: {
    "& > .MuiFormControl-root": {
      width: "95%",
    },
  },
});

interface Props {
  handleClose: any;
  modalProps?: any;
}
interface CampaingParams {
  expiredAt: string;
  prizeCount: number;
}

const convertToCustomerLimit = (limit: any) => {
  return limit === "no limit" ? -1 : Number(limit.slice(0, -1));
};

const getDiff = (data: any, modalProps: any) => {
  const prizeCount = toNumber(data.prizeCount);
  const editTime = new Date(data.expiredAt);
  const prizeTime = new Date(modalProps.expiredAt);

  return {
    ...(prizeCount !== modalProps.prizeCount ? { prizeCount } : {}),
    ...(differenceInDays(editTime, prizeTime)
      ? { expiredAt: editTime.toISOString() }
      : {}),
    ...(data.name !== modalProps.name ? { name: data.name } : {}),
    ...(!isEqual(data.categories.sort(), modalProps.categories.sort())
      ? {
          categories: data.categories.map((item: string) =>
            isString(item) ? JSON.parse(item) : item
          ),
        }
      : {}),
    ...(Number(data.price) !== modalProps.price
      ? { price: Number(data.price) }
      : {}),
    ...(data.prizeType !== modalProps.prizeType
      ? { prizeType: data.prizeType }
      : {}),
    ...(data.terms !== modalProps.terms ? { terms: data.terms } : {}),
    ...(data.perCustomerLimit !== modalProps.perCustomerLimit
      ? { perCustomerLimit: convertToCustomerLimit(data.perCustomerLimit) }
      : {}),
    ...(data.ageLimit !== modalProps.ageLimit
      ? { ageLimit: Number(data.ageLimit.slice(0, -1)) }
      : {}),
  };
};

export const editCompaign = (id: any, value: CampaingParams): Promise<any> =>
  axios.patch(`/retailer-prize/prize/${id}`, value).then((res) => res);

const EditCampaign = ({ handleClose, modalProps }: Props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-height:700px)");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      expiredAt: modalProps.expiredAt,
      prizeCount: modalProps.prizeCount,
      name: modalProps.name,
      price: modalProps.price,
      prizeType: modalProps.prizeType,
      categories: modalProps.categories,
      ageLimit: modalProps.ageLimit,
      perCustomerLimit: modalProps.perCustomerLimit,
      terms: modalProps.terms,
    },
    mode: "all",
  });

  const values = watch();

  const formValues = watch();

  const datePlusYear = new Date();
  datePlusYear.setDate(datePlusYear.getDate() + 365);

  React.useEffect(() => {
    if (formValues.price < 0) {
      setValue("price", 0);
    }
    if (formValues.prizeCount < 0) {
      setValue("prizeCount", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const queryClient = useQueryClient();

  const [dateValue, setDateValue] = React.useState<Date | null>(
    new Date(modalProps.expiredAt)
  );

  const { mutate, isLoading, isSuccess } = useMutation(
    (value: CampaingParams) => editCompaign(modalProps?.id, value)
  );

  const { currentFiles, updatePhoto, patchPhotos } = useFileUpload(
    modalProps.image
  );

  const [categoriesOptions, setCategoriesOptions] = React.useState([]);

  React.useEffect(() => {
    axios.get(`/retailer-prize/category`).then(({ data }) => {
      setCategoriesOptions(data);
    });
  }, []);

  const isEmptyPhotos = Object.values(currentFiles)
    .reduce((acc: boolean[], value: any) => {
      return [...acc, isEmpty(value) || value?.isCropped === false];
    }, [])
    .every((v) => v === false);

  const handleClick = async (data: { [x: string]: any }) => {
    const resultObject = getDiff(data, modalProps);

    if (!isEmpty(resultObject)) {
      mutate(resultObject as CampaingParams);
    }

    await patchPhotos(
      `/retailer-prize/prize/${modalProps?.id}/image`,
      `/retailer-prize/prize/${modalProps?.id}/image`
    );

    handleClose();

    setTimeout(() => {
      queryClient.invalidateQueries("сampaignById");
    }, 500);
  };

  if (isSuccess) {
    handleClose();
  }
  return (
    <div className={classes.contentWrapper}>
      <form
        className={classes.form}
        onSubmit={handleSubmit((data) => handleClick(data))}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Prize
        </Typography>
        <Grid
          container
          className={classNames({ [classes.containerModal]: matches })}
        >
          <Grid item xs={6} className={classes.item}>
            <ModalInput
              size="small"
              label="Describe offer"
              placeholder="Enter Offer"
              error={!!errors?.name}
              helperText={
                (errors?.name as any)?.type ? "Description Required" : ""
              }
              className={classNames({ required: errors?.name })}
              {...register("name", { required: true })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <ModalInput
              label="How many prizes are available? "
              placeholder="Enter Number"
              type="number"
              size="small"
              error={!!errors?.prizeCount}
              helperText={
                (errors?.prizeCount as any)?.type
                  ? (errors?.prizeCount as any)?.type === "max"
                    ? "Count shouldn't be more than 500"
                    : modalProps.prizeCount
                    ? "You can't reduce amount of available prizes"
                    : "Count is Required and should be more then 0"
                  : ""
              }
              className={classNames({ required: errors?.prizeCount })}
              {...register("prizeCount", {
                required: true,
                min: modalProps.prizeCount,
                max: 500,
              })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <ModalInput
              size="small"
              label="Value Of Prize, $"
              placeholder="Enter Value"
              type="number"
              className={classNames({ required: errors?.price })}
              error={!!errors?.price}
              helperText={
                (errors?.price as any)?.type
                  ? (errors?.price as any)?.type === "max"
                    ? "Price shouldn't be more than 500"
                    : modalProps.price
                    ? "You can't reduce price of available prizes"
                    : "Price is Required and should be more then 0"
                  : ""
              }
              {...register("price", {
                required: true,
                min: modalProps.price || 1,
                max: 500,
              })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <CustomSelect
              {...control}
              options={["1x", "2x", "3x", "no limit"]}
              errorMessage="Redemption per user is Required"
              label="Choose Redemption per user"
              selectLabel="Choose Redemption per user"
              placeholder="Choose Redemption per user"
              name="perCustomerLimit"
              size="small"
              multiple={false}
              isCommon
              errors={errors}
              className={classNames({ required: errors?.perCustomerLimit })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <CustomSelect
              {...control}
              options={["18+", "21+", "25+", "30+", "40+"]}
              errorMessage="Age Limit Required"
              label="Choose Age Limit"
              selectLabel="Choose Age Limit"
              placeholder="Choose Age Limit"
              name="ageLimit"
              size="small"
              multiple={false}
              isCommon
              errors={errors}
              className={classNames({ required: errors?.ageLimit })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <CustomSelect
              {...control}
              size="small"
              options={categoriesOptions}
              errors={errors}
              className={classNames({ required: errors?.categories })}
            />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                minDate={new Date(modalProps.expiredAt)}
                maxDate={datePlusYear}
                disablePast
                renderInput={(props: any) => (
                  <ModalInput
                    size="small"
                    {...props}
                    {...register("expiredAt", { valueAsDate: true })}
                  />
                )}
                label="Expiration Date"
                value={dateValue}
                onChange={(newDate) => {
                  setValue("expiredAt", newDate);
                  setDateValue(newDate);
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} className={classes.itemFullWith}>
            <ModalInput
              label="Enter your terms and conditions"
              placeholder="list any restrictions on when a user can redeem this prize"
              multiline
              rows={4}
              className={classNames([classes.textFieled], {
                required: errors?.terms,
              })}
              error={!!errors?.terms}
              helperText={
                (errors?.terms as any)?.type
                  ? (errors?.terms as any)?.type === "maxLength"
                    ? "Terms and conditions shouldn't contain more than 1500 letters"
                    : "Terms and conditions is Required"
                  : ""
              }
              {...register("terms", {
                required: true,
                minLength: 0,
                maxLength: 1500,
              })}
            />
          </Grid>
          <Grid item xs={6} className={classes.itemPhoto}>
            <DragAndDrop
              name="prize-photo-big"
              label="Big prize photo 1080x1080"
              type="BIG"
              images={currentFiles}
              uploadPhoto={updatePhoto}
            />
          </Grid>
          <Grid item xs={6} className={classes.itemPhoto}>
            <DragAndDrop
              name="prize-photo-smal"
              label="Small prize photo 662x280"
              type="SMALL"
              images={currentFiles}
              uploadPhoto={updatePhoto}
            />
          </Grid>
        </Grid>

        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              (dateValue !== null &&
                (differenceInCalendarDays(dateValue, new Date()) < 0 ||
                  differenceInCalendarDays(dateValue, new Date()) > 365)) ||
              isLoading ||
              (isEmpty(getDiff(values, modalProps)) &&
                differenceWith(
                  Object.values(currentFiles).filter(
                    (obj: any) => !(obj && Object.keys(obj).length === 0)
                  ),
                  modalProps.image,
                  isEqual
                ).length === 0) ||
              Object.values(currentFiles).filter(
                (item: any) => item?.removed === true
              ).length !== 0 ||
              !isEmptyPhotos
            }
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
export default EditCampaign;
