import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, SubmitHandler } from "react-hook-form";
import { isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";

import { ROUTES, USER_TOKEN } from "../../../constants";
import { set } from "helpers/storage";

import { CustomInput, useStyles } from "../LoginPage";

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password length should be at least 4 characters"),
  retryPassword: Yup.string()
    .required("Confirm Password is required")
    .min(4, "Password length should be at least 4 characters")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

interface IFormInput {
  retryPassword: string;
  password: string;
}

const setPassword = (
  data: IFormInput,
  config: any
): Promise<{ sessionId: string }> =>
  axios.patch("/retailer/password", data, config).then(({ data }: any) => data);

const SetPasswordStep = ({ setCurrentStep, currentTokens }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(formSchema),
  });

  const { mutate, isLoading, isError } = useMutation(
    (data: IFormInput) =>
      setPassword(data, {
        headers: {
          "Auth-Token": currentTokens?.authToken,
        },
      }),
    {
      onSuccess: () => {
        set(USER_TOKEN, currentTokens);
        navigate(`/${ROUTES.createProfile}`);
      },
    }
  );

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    mutate(data);
  };
  return (
    <form className={classes.customForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.wrapper}>
        <Typography variant="body1" className={classes.title}>
          Set your new password
        </Typography>
        <CustomInput
          variant="filled"
          size="small"
          type="text"
          label="Password"
          {...register("password", {
            required: true,
          })}
        />
        {errors?.password?.message ? (
          <span>{errors?.password?.message}</span>
        ) : null}
        {/* {isError ? <span> Invalid email and/or password</span> : null} */}
        <CustomInput
          variant="filled"
          size="small"
          type="text"
          label="Confirm Password"
          {...register("retryPassword", {
            required: true,
          })}
        />
        {errors?.retryPassword?.message ? (
          <span>{errors?.retryPassword?.message}</span>
        ) : null}
      </div>
      <LoadingButton
        loading={isLoading}
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isEmpty(errors) && !isValid}
      >
        Set Password
      </LoadingButton>
    </form>
  );
};

export default SetPasswordStep;
