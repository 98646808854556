import { centerCrop, makeAspectCrop } from "react-image-crop";

export const updatePhotoSize = (
  url: string,
  width: number = 1080,
  height: number = 1080
) => {
  // Create a new image element
  const img = new Image();

  // Set the source URL
  img.src = url;

  // Wait for the image to load
  return new Promise((resolve) => {
    img.onload = () => {
      // Get the dimensions of the image
      const imgWidth = img.width;
      const imgHeight = img.height;

      // Determine the maximum width and height that the image can be while
      // maintaining its aspect ratio and fitting within a square canvas
      const maxCanvasWidth = width; // Set the maximum canvas size here
      const maxCanvasHeight = height; // Set the maximum canvas size here

      const maxCanvasLine = Math.min(maxCanvasWidth, maxCanvasHeight);

      const maxSize = Math.max(
        imgWidth,
        imgHeight,
        maxCanvasWidth,
        maxCanvasHeight
      );
      const scale = maxCanvasLine / maxSize;
      const canvasWidth = Math.round(imgWidth * scale);
      const canvasHeight = Math.round(imgHeight * scale);

      // Calculate the x and y offsets to center the image within the canvas
      const xOffset = Math.max(
        0,
        Math.round((maxCanvasWidth - canvasWidth) / 2)
      );
      const yOffset = Math.max(
        0,
        Math.round((maxCanvasHeight - canvasHeight) / 2)
      );

      // Create a new canvas element with the same dimensions as the canvas
      const canvas = document.createElement("canvas");
      canvas.width = maxCanvasWidth;
      canvas.height = maxCanvasHeight;

      // Get the 2D drawing context for the canvas
      const ctx = canvas.getContext("2d");

      // Fill the canvas with white
      ctx!.fillStyle = "#fff";
      ctx!.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the image onto the canvas with the calculated offsets and dimensions
      ctx!.drawImage(img, xOffset, yOffset, canvasWidth, canvasHeight);

      // Create a new image element with the centered image data URL
      const centeredImg = new Image();
      centeredImg.src = canvas.toDataURL();
      centeredImg.onload = () => {
        // Resolve the promise with the data URL
        resolve(centeredImg.src);
      };
    };
  });
};

export const getImageSize = (type: string) => {
  switch (type) {
    case "INTERIOR":
    case "PROFILE":
    case "BIG":
      return {
        width: 1080,
        height: 1080,
      };
    case "LOGO_LARGE":
      return {
        width: 1920,
        height: 1080,
      };
    case "SMALL":
      return {
        width: 993,
        height: 420,
      };
    default:
      return {
        width: 1080,
        height: 1080,
      };
  }
};
export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
