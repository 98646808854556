import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty } from "lodash";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { CustomInput, useStyles, LoginSteps } from "../LoginPage";
import { useForm, SubmitHandler } from "react-hook-form";
import useLogin from "hooks/useLogin";

interface IFormInput {
  username: string;
  password: string;
}

export const CustomPasswordInput = styled(FilledInput)(() => ({
  background: "#1A162C",
  border: "1px solid #342D5C",
  marginBottom: "10px",
  borderRadius: "5px",
  "& .MuiFilledInput-input": {
    color: "#7477A0",
  },
}));

const LoginStep = ({ setCurrentStep, currentEmail = "" }: any) => {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<IFormInput>({
    defaultValues: {
      username: currentEmail,
    },
  });

  const { mutate, isLoading, isError } = useLogin(true);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    mutate(data);
  };
  const [showPassword, setPassword] = React.useState(true);
  const handleClickShowPassword = () => {
    setPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <form className={classes.customForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.wrapper}>
          <CustomInput
            variant="filled"
            size="small"
            type="text"
            label="User name"
            {...register("username", {
              required: true,
              // pattern: EMAIL_REGEXP,
            })}
          />
          {errors?.username?.type === "pattern" ? (
            <span>Enter valid email</span>
          ) : null}
          {isError ? <span> Invalid email and/or password</span> : null}
        </div>
        <div className={classes.wrapper}>
          <FormControl variant="filled">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <CustomPasswordInput
              size="small"
              id="outlined-adornment-password"
              type={showPassword ? "password" : "text"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPassword ? (
                      <VisibilityOff className={classes.icon} />
                    ) : (
                      <Visibility className={classes.icon} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              {...register("password", {
                required: true,
              })}
            />
          </FormControl>
        </div>
        <LoadingButton
          loading={isLoading}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isEmpty(errors) && !isValid}
        >
          Login
        </LoadingButton>
      </form>
      <button
        className={classes.resetBtn}
        onClick={() => setCurrentStep(LoginSteps.EMAIL_STEP)}
      >
        Back to login
      </button>
    </>
  );
};

export default LoginStep;
