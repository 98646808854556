import React from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { ROUTES, MODALS } from "../../constants";
import useModals from "hooks/useModals";
import Modal from "components/modals/Modal";
import useResize from "hooks/useResize";
import { handleResizeTable } from "helpers/resize";
import { isEmpty } from "lodash";

const columns: GridColDef[] = [
  {
    field: "prizeItemId",
    headerName: "Prize Item Id",
    width: 110,
    editable: true,
  },
  {
    field: "prize",
    headerName: "Prize",
    type: "string",
    width: 100,
    editable: true,
  },
  {
    field: "redeemedAt",
    headerName: "Redeemed At",
    width: 150,
    editable: true,
    renderCell: (value) => {
      if (!value.formattedValue) {
        return "—";
      }
      return moment(value.formattedValue).format("YYYY-MM-DD hh:mm A");
    },
  },
  {
    field: "retailerId",
    headerName: "Retailer Id",
    width: 100,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    editable: true,
  },
  {
    field: "wonAt",
    headerName: "Won At",
    width: 150,
    editable: true,
    renderCell: (value) => {
      if (!value.formattedValue) {
        return "—";
      }
      return moment(value.formattedValue).format("YYYY-MM-DD hh:mm A");
    },
  },
  {
    field: "wonByCustomerId",
    headerName: "Won By Customer Id",
    width: 150,
    editable: true,
  },
];

const useStyles = makeStyles({
  titleWrapper: {
    paddingBottom: "20px",
  },
  backButton: {
    background: "#7459d7",
    marginRight: "20px",
    color: "white",
    cursor: "pointer",
  },
  dataTableWrapper: {
    width: "100%",
    height: "100%",
    "& .MuiDataGrid-root": {
      color: "#7B7B8B",
    },
    "& .MuiTablePagination-toolbar": {
      color: "white",
    },
    "& .MuiIconButton-root": {
      "&.Mui-disabled": {
        color: "white",
        opacity: 0.2,
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      "& button": {
        color: "white",
      },
    },
    "& .MuiDataGrid-menuIcon": {
      "& button": {
        color: "white",
      },
    },
  },
  infoWrapper: {
    flexWrap: "wrap",
    alignItems: "center",
  },
  wrapper: {
    flexWrap: "wrap",
    alignItems: "center",
  },
  image: {
    width: "180px",
    height: "100px",
    objectFit: "contain",
    marginLeft: "40px",
  },
  buttonPosition: {
    margin: "0 15px 0 auto",
  },
  infoItem: {
    marginBottom: "10px",
    fontSize: "15px",
    "& span": {
      "&:first-of-type": {
        fontWeight: 600,
        color: "white",
      },
    },
  },
});

export const getCampaignById = (id?: string): Promise<any> =>
  axios.get(`/retailer-prize/prize/${id}`).then((res) => res);

const PrizeListDetails = () => {
  const heightRef = React.useRef(null);
  const classes = useStyles();
  const size = useResize();
  const navigate = useNavigate();

  let { id } = useParams();

  const { data, isLoading } = useQuery(
    "сampaignById",
    () => getCampaignById(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { open, handleOpen, handleClose } = useModals();
  const rowCount = handleResizeTable(size[0], size[1], heightRef?.current);

  const getPrizeInfo = (info: any) => {
    if (!info) {
      return null;
    }
    const { images, name, price, prizeType } = info;

    const imageBigUrl = images?.find((item: any) => item.type === "BIG")?.url;
    const imageSmallUrl = images?.find(
      (item: any) => item.type === "SMALL"
    )?.url;

    return (
      <Grid display="flex" className={classes.wrapper}>
        <div className={classes.infoWrapper}>
          <div className={classes.infoItem}>
            <span>Name : </span>
            <span>{name}</span>
          </div>
          <div className={classes.infoItem}>
            <span>Price : </span>
            <span>{price}</span>
          </div>
          <div className={classes.infoItem}>
            <span>Prize Type : </span>
            <span>{prizeType}</span>
          </div>
        </div>

        {!isEmpty(imageBigUrl) ? (
          <img className={classes.image} src={imageBigUrl} alt="" />
        ) : null}
        {!isEmpty(imageSmallUrl) ? (
          <img className={classes.image} src={imageSmallUrl} alt="" />
        ) : null}
      </Grid>
    );
  };

  return (
    <>
      <Container>
        <Grid container spacing={0} flexDirection="column">
          <div className={classes.titleWrapper} ref={heightRef}>
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ArrowBackIcon
                className={classes.backButton}
                onClick={() => {
                  navigate(`/${ROUTES.prizeList}`);
                }}
              />
              <h1>Prize Details</h1>
              <Button
                onClick={() =>
                  handleOpen(MODALS.EDIT_CAMPAIGN_PRIZE, {
                    id: data?.data?.prizeId,
                    expiredAt: data?.data?.expiredAt,
                    prizeCount: data?.data?.prizeCount,
                    name: data?.data?.name,
                    price: data?.data?.price,
                    prizeType: data?.data?.prizeType,
                    image: data?.data?.images,
                    categories:
                      data?.data?.categories?.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                      })) || [],
                    perCustomerLimit:
                      data?.data?.perCustomerLimit === -1 ||
                      data?.data?.perCustomerLimit === null
                        ? "no limit"
                        : `${data?.data?.perCustomerLimit}x`,
                    ageLimit: data?.data?.ageLimit
                      ? `${data?.data?.ageLimit}+`
                      : "",
                    offerDiscount: data?.data?.offerDiscount,
                    terms: data?.data?.terms,
                  })
                }
                variant="contained"
                color="primary"
                endIcon={<EditIcon />}
                className={classes.buttonPosition}
              >
                Edit Prize
              </Button>
            </Grid>

            {/* <Grid display="flex" className={classes.infoWrapper}>
              {getCampaignsInfo(data?.data)}
            </Grid> */}
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            ></Grid>
            <Grid> {getPrizeInfo(data?.data)}</Grid>
          </div>
          <div className={classes.dataTableWrapper}>
            <DataGrid
              rows={data?.data?.prizes || []}
              loading={isLoading}
              columns={columns}
              pageSize={rowCount}
              rowsPerPageOptions={[data?.data?.prizes?.length]}
              disableSelectionOnClick
              autoHeight
              getRowId={(r) => r.prizeItemId}
              isCellEditable={() => false}
            />
          </div>
        </Grid>
      </Container>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.EDIT_CAMPAIGN_PRIZE}
      />
    </>
  );
};
export default PrizeListDetails;
