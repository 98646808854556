import React from "react";
import Box from "@mui/material/Box";
import MaterialModal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import InviteEmployees from "./InviteEmployees";
import AddCampaign from "./AddCampaign";
import EditCampaign from "./EditCampaign";
import EditProfile from "./EditProfile";
import EditOffer from "./EditOffer";
import CropImage from "./CropImage";
import RedeemPrize from "./RedeemPrize";
import AddOffer from "./AddOffer";
import { MODALS } from "../../constants";

const useStyles = makeStyles({
  modalWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  content: {
    backgroundColor: "#201A3E",
  },
});

interface Props {
  handleClose: any;
  name: any;
  modalProps: any;
}

const Modal = ({ modalProps, handleClose, name }: Props) => {
  const classes = useStyles();

  const closeModal = () => {
    handleClose(name);
  };

  const getModals = (modalName: string) => {
    const restProps = modalProps?.[modalName]?.props;

    switch (modalName) {
      case MODALS.ADD_CAMPAIGN:
        return <AddCampaign handleClose={closeModal} />;
      case MODALS.ADD_OFFER:
        return <AddOffer handleClose={closeModal} />;
      case MODALS.EDIT_CAMPAIGN_PRIZE:
        return <EditCampaign handleClose={closeModal} modalProps={restProps} />;
      case MODALS.EDIT_OFFER:
        return <EditOffer handleClose={closeModal} modalProps={restProps} />;
      case MODALS.EDIT_PROFILE:
        return <EditProfile handleClose={closeModal} modalProps={restProps} />;
      case MODALS.CHECK_REDEEM:
        return <RedeemPrize handleClose={closeModal} />;
      case MODALS.INVITE_EMPLOYEES:
        return <InviteEmployees handleClose={closeModal} />;
      case MODALS.CROP_IMAGE:
        return <CropImage handleClose={closeModal} modalProps={restProps} />;
      default:
        return null;
    }
  };

  return (
    <MaterialModal
      open={modalProps?.[name]?.open || false}
      onClose={closeModal}
      aria-labelledby={`modal-modal-title-${name}`}
      aria-describedby={`modal-modal-description-${name}`}
    >
      <Box className={classes.modalWrapper}>
        <div className={classes.content}>{getModals(name)}</div>
      </Box>
    </MaterialModal>
  );
};
export default Modal;
