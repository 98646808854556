import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Skeleton from "@mui/material/Skeleton";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  paperWrapper: {
    display: "flex",
    marginBottom: "25px",
  },
  boxStyle: {
    width: 160,
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    margin: "10px",
    boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)",
    "& svg": {
      color: "white",
    },
  },
  boxStyleContent: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    color: "white",
    "& span:first-of-type": {
      fontSize: "1.2em",
      fontWeight: 600,
    },
    "& span:last-of-type": {
      textTransform: "capitalize",
    },
  },
});

export const getDashboardInfo = (): Promise<any> =>
  axios.get(`/retailer-prize/dashboard/by-status`).then((res) => res);

const Dashboard = () => {
  const classes = useStyles();
  const { data, isLoading } = useQuery("dashbord", () => getDashboardInfo(), {
    refetchOnWindowFocus: false,
  });

  return (
    <div className={classes.paperWrapper}>
      {isLoading ? (
        <>
          <Skeleton
            sx={{ bgcolor: "grey.400" }}
            variant="rectangular"
            width={160}
            height={65}
            className={classes.boxStyle}
          />
          <Skeleton
            sx={{ bgcolor: "grey.400" }}
            variant="rectangular"
            width={160}
            height={65}
            className={classes.boxStyle}
          />
          <Skeleton
            sx={{ bgcolor: "grey.400" }}
            variant="rectangular"
            width={160}
            height={65}
            className={classes.boxStyle}
          />
        </>
      ) : (
        <>
          {Object.entries(data?.data as { [key: string]: number }).map(
            (item) => {
              const [name, count] = item;
              return (
                <Box key={name} className={classes.boxStyle}>
                  <EmojiEventsIcon />
                  <div className={classes.boxStyleContent}>
                    <span>{count}</span>
                    <span>{name}</span>
                  </div>
                </Box>
              );
            }
          )}
        </>
      )}
    </div>
  );
};
export default Dashboard;
