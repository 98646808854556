import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { beLinks, ENV } from "./constants";

import App from "./App";

const customTheme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            background:
              "transparent linear-gradient(90deg, #F07C30 0%, #ED4F1E 57%, #FD371C 100%) 0% 0% no-repeat padding-box",
            boxShadow: " 0px 4px 5px #855F9B62",
            "&.Mui-disabled": {
              background: "rgba(255, 255, 255, 0.3)",
              boxShadow: " 0px 4px 5px #855F9B62",
            },
          },
        },
        {
          props: { color: "secondary" },
          style: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 4px 5px #0B0F326B",
            borderRadius: " 6px",
            opacity: 1,
            color: "#000000",
            "&:hover": {
              background: "#FFFFFF 0% 0% no-repeat padding-box",
            },
          },
        },
        {
          props: { color: "info" },
          style: {
            background: "transparent",
            color: "white",
            boxShadow: "0px 4px 5px #0B0F326B",
            borderRadius: "0",
            opacity: 1,
          },
        },
      ],
    },
  },
});

axios.defaults.baseURL = `${beLinks[window._env_.REACT_APP_ENV as ENV]}/v1`;

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={customTheme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
