import React from "react";
import { makeStyles } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import classNames from "classnames";
import Grid from "@mui/material/Grid";
import CropIcon from "@mui/icons-material/Crop";

import { ModalInput } from "components/inputs";
import { MediaGP } from "models/media";
import useModals from "hooks/useModals";
import Modal from "components/modals/Modal";
import { MODALS } from "../../constants";
import { updatePhotoSize } from "helpers/updatePhotoSize";

const useStyles = makeStyles({
  dragAndDrop: {
    border: "1px solid black",
    borderRadius: "5px",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  thumWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    gridGap: "20px",
  },
  inputWrapper: {
    "&.MuiFormControl-root": {
      margin: "40px 0 10px 0",
      "& .MuiInputLabel-root": {
        transform: "translate(0, 0) scale(0.75)",
        top: "-25px",
      },
    },
  },
  thumb: {
    display: "inline-flex",
    boxSizing: "border-box",
    position: "relative",
    justifyContent: "center",
  },
  textFieled: {
    flex: "1 0 68%",
    "& .MuiOutlinedInput-root": {
      marginBottom: "unset",
      marginRight: "10px",
    },
    "& .MuiFormHelperText-root": {
      bottom: "0",
    },
  },
  thumbRegular: {
    width: 90,
    height: 90,
  },
  mainThumb: {
    flex: "1 0",
    "& video": {
      width: "100%",
    },
  },
  videoMainThumb: {
    flex: "1 0 200px",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "100%",
    objectFit: "contain",
  },
  icon: {
    background: "#eb62ff33",
    borderRadius: "50%",
    position: "absolute",
    right: "-15px",
    top: "-5px",
    padding: "2px",
    cursor: "pointer",
    color: "#EB62FF",
  },
  iconLeft: {
    left: "-10px",
  },
  thumGrid: {
    marginBottom: "15px",
    position: "relative",
  },
  isNotCropped: {
    border: "1px solid red",
  },
  croppedErrror: {
    position: "absolute",
    bottom: "-28px",
    fontSize: "13px",
    color: "red",
  },
});

const maxRegularDescription = 124;

const Thumb = ({
  onRemove,
  isMain = false,
  onChangeDescription,
  file,
  updatePhoto,
}: {
  onRemove: any;
  isMain?: boolean;
  onChangeDescription: any;
  file: MediaGP;
  updatePhoto: any;
}) => {
  const id = file.mediaId || file.uuid;
  const url = file?.preview || file.url || "";
  const name = file.media?.name || file.name;
  const description = file.description;

  const classes = useStyles();
  const isVideo = file?.media?.type.includes("video");
  const lengthValidation = !(description.length < maxRegularDescription);
  const minLengthValidation = !(description.length > 0);
  const { open, handleOpen, handleClose } = useModals();
  return (
    <>
      <Grid item xs={isMain ? 12 : 6} className={classes.thumGrid}>
        <div className={classes.thumWrapper}>
          <div
            className={classNames(
              [classes.thumb],
              { [classes.mainThumb]: isMain },
              { [classes.videoMainThumb]: isVideo },
              { [classes.thumbRegular]: !isMain },
              { [classes.isNotCropped]: file.isCropped === false }
            )}
          >
            {!isVideo &&
              file.hasOwnProperty("isCropped") &&
              !file.isCropped && (
                <CropIcon
                  onClick={() => {
                    handleOpen(MODALS.CROP_IMAGE, {
                      src:
                        file?.url ||
                        URL.createObjectURL(file.media as unknown as Blob),
                      updatePhoto: updatePhoto,
                      aspect: isMain ? 0.67 : 1,
                    });
                  }}
                  className={classNames([classes.iconLeft, classes.icon])}
                />
              )}

            <DeleteOutlineIcon onClick={onRemove} className={classes.icon} />
            <div className={classes.thumbInner}>
              {isVideo ? (
                <video width="220" height="160" controls>
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={url}
                  className={classes.img}
                  alt={name}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(url);
                  }}
                />
              )}
            </div>
          </div>
          <ModalInput
            placeholder="Provide your description"
            multiline
            rows={isMain ? 6 : 2}
            value={description}
            onChange={(event) => {
              onChangeDescription(id, event.target.value);
            }}
            error={lengthValidation || minLengthValidation}
            helperText={
              (lengthValidation &&
                `Description should be less then ${maxRegularDescription} letters`) ||
              (minLengthValidation && `Description is Required`)
            }
            className={classNames([classes.textFieled])}
          />
        </div>
        {file.isCropped === false && (
          <p className={classes.croppedErrror}>Please crop the image</p>
        )}
      </Grid>
      <Modal
        modalProps={open}
        handleClose={handleClose}
        name={MODALS.CROP_IMAGE}
      />
    </>
  );
};
export default Thumb;
