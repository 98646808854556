import axios from "axios";
import { useEffect, useState } from "react";

const useAuthorizationHeader = (token?: string | null) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (token) {
      axios.defaults.headers["Auth-Token"] = `${token}`;
    } else {
      delete axios.defaults.headers["Auth-Token"];
    }
    setIsLoggedIn(!!token);
    setLoading(false);
  }, [token]);

  return [isLoggedIn, loading];
};

export default useAuthorizationHeader;
