import React from "react";
import { styled } from "@mui/styles";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import classNames from "classnames";

import { AutocompleteInput } from "components/inputs";

const FormHelperTextStyled = styled(FormHelperText)(() => ({
  "&.MuiFormHelperText-root": {
    bottom: "18px",
    position: "absolute",
  },
}));

interface Props {
  setFormValue: any;
  control: any;
  errors: any;
  modalProps: any;
  clearErrors: any;
}

const AddressAutocomplete: React.FC<Props> = ({
  setFormValue,
  control,
  errors,
  modalProps,
  clearErrors,
}: Props) => {
  const [open, setOpen] = React.useState(false);

  const {
    ready,
    suggestions: { data: adressData },
    setValue,
  } = usePlacesAutocomplete();

  const loading = open && adressData.length === 0;

  return (
    <FormControl>
      <Controller
        name="address"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <AutocompleteInput
            {...field}
            disablePortal
            open={open}
            isOptionEqualToValue={() => {
              return true;
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            options={adressData.map((item) => item.description)}
            disabled={!ready}
            onChange={(_event, value) => {
              setFormValue("address", value);
              if (value) {
                getGeocode({ address: value }).then((results) => {
                  const { lat, lng } = getLatLng(results[0]);
                  setFormValue("geoLocation", `${lat},${lng}`);
                });
              } else {
                setFormValue("geoLocation", "");
              }
              clearErrors("address");
            }}
            onInputChange={(_event: any, value: any) => {
              setValue(value);
              setFormValue("address", value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Provide your address (Address, city state, zip)"
              />
            )}
            placeholder="Ex. '3456 Magnolia street, Orlando, Florida, 18970'"
            className={classNames({ required: errors?.address })}
          />
        )}
      />
      <FormHelperTextStyled error={!!errors?.address}>
        {(errors?.address as any)?.type ? "Address is Required" : ""}
      </FormHelperTextStyled>
    </FormControl>
  );
};

export default AddressAutocomplete;
