import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useMutation, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { isEmpty, differenceWith, isEqual, debounce } from "lodash";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";

import DragAndDrop from "components/DragAndDrop";
import useFileUpload from "hooks/useFileUpload";
import { ModalInput, AddressAutocomplete } from "components/inputs";

const useStyles = makeStyles({
  containerModal: {
    height: "350px",
    overflow: "scroll",
    paddingTop: "15px",
  },
  contentWrapper: {
    padding: "20px",
    width: "850px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  item: {
    "& > .MuiFormControl-root": {
      width: "95%",
      "& > .MuiAutocomplete-root": {
        width: "100%",
      },
    },
  },
  itemFullWith: {
    "& > .MuiFormControl-root": {
      width: "97.5%",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    "& > button": {
      margin: "0 10px",
      minWidth: "90px",
    },
  },
  itemPhoto: {
    "& > .MuiFormControl-root": {
      width: "95%",
    },
  },
});

interface Props {
  handleClose: any;
  modalProps?: any;
}
interface CampaingParams {
  expiredAt: string;
  prizeCount: number;
}

const getDiff = (data: any, modalProps: any) => {
  return {
    ...(data.name !== modalProps.name ? { name: data.name } : {}),
    ...(data.address !== modalProps.address ? { address: data.address } : {}),
    ...(data.url !== modalProps.url ? { url: data.url } : {}),
    ...(data.geoLocation !== modalProps.geoLocation
      ? { geoLocation: data.geoLocation }
      : {}),
  };
};

const getSocialLink = (link: string) => {
  return { socialReview: { url: link } };
};

export const editProfile = (value: CampaingParams): Promise<any> =>
  axios.patch("/retailer-profile/profile", value).then((res) => res);

const debounceValue = debounce(
  (value: string, setFormValue: any, clearErrors: any) => {
    if (value.match(/yelp./i)) {
      axios
        .post("/retailer-profile/info-by-url", {
          url: value,
        })
        .then(({ data }) => {
          if (data?.coordinates) {
            setFormValue(
              "geoLocation",
              `${data?.coordinates?.latitude ?? 0},${
                data?.coordinates?.longitude ?? 0
              }`
            );
          }
          if (data.name) {
            setFormValue("name", data.name);
          }
          if (data.location) {
            setFormValue(
              "address",
              (data?.location?.display_address || []).join(" ,")
            );
          }
          clearErrors();
        });
    }
  },
  600
);

const EditProfile = ({ handleClose, modalProps }: Props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-height:600px)");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue: setFormValue,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: modalProps.name,
      address: modalProps.address,
      url: modalProps?.url,
      geoLocation: modalProps?.geoLocation,
    },
  });

  register("geoLocation");
  const values = watch();

  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    (value: CampaingParams) => editProfile(value)
  );

  const { currentFiles, updatePhoto, patchPhotos } = useFileUpload(
    modalProps.images,
    "PROFILE",
    "LOGO_LARGE",
    "INTERIOR"
  );

  const handleClick = async (data: { [x: string]: any }) => {
    let resultObject = getDiff(data, modalProps);

    if (!isEmpty(resultObject)) {
      if (resultObject.url) {
        const { url, ...restFields } = resultObject;
        resultObject = { ...restFields, ...getSocialLink(url) };
      }
      mutate(resultObject as CampaingParams);
    }

    await patchPhotos(`/retailer-profile/image`, `/retailer-profile/image`);

    handleClose();

    setTimeout(() => {
      queryClient.invalidateQueries("profile");
    }, 500);
  };

  if (isSuccess) {
    handleClose();
  }

  const isEmptyPhotos = Object.values(currentFiles)
    .reduce((acc: boolean[], value: any) => {
      const result = [
        ...acc,
        !!value?.removed || isEmpty(value) || value?.isCropped === false,
      ];

      return result;
    }, [])
    .every((v) => v === false);
  return (
    <div className={classes.contentWrapper}>
      <form
        className={classes.form}
        onSubmit={handleSubmit((data) => handleClick(data))}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Profile
        </Typography>
        <Grid
          container
          className={classNames({ [classes.containerModal]: matches })}
        >
          <Grid item xs={12} className={classes.item}>
            <ModalInput
              label="Add the link which represents you"
              placeholder="Link from Google, Yelp"
              className={classNames({ required: errors?.url })}
              error={!!errors?.url}
              helperText={
                (errors?.url as any)?.type
                  ? "Link is Required, Please enter Google or Yelp link"
                  : ""
              }
              {...register("url", {
                pattern: /yelp.|google/i,
              })}
              onChange={(event: any) => {
                if (event.target.value.match(/yelp/i)) {
                  debounceValue(event.target.value, setFormValue, clearErrors);
                }
                setFormValue("url", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ModalInput
                  {...field}
                  label="Merchants name"
                  placeholder="Enter Merchants name"
                  error={!!errors?.name}
                  helperText={
                    (errors?.name as any)?.type
                      ? "Merchants name is Required"
                      : ""
                  }
                  className={classNames({ required: errors?.name })}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <AddressAutocomplete
              setFormValue={setFormValue}
              control={control}
              errors={errors}
              modalProps={modalProps}
              clearErrors={clearErrors}
            />
          </Grid>
          <Grid item xs={4} className={classes.itemPhoto}>
            <DragAndDrop
              name="profile-photo"
              label="Profile photo   1080x1080"
              buttonLabel="Add profile photo"
              type="PROFILE"
              images={currentFiles}
              uploadPhoto={updatePhoto}
            />
          </Grid>
          <Grid item xs={4} className={classes.itemPhoto}>
            <DragAndDrop
              name="profile-photo-logo"
              label="Logo photo   1920x1080"
              buttonLabel="Add logo photo"
              type="LOGO_LARGE"
              images={currentFiles}
              uploadPhoto={updatePhoto}
            />
          </Grid>
          <Grid item xs={4} className={classes.itemPhoto}>
            <DragAndDrop
              name="profile-photo-interior"
              label="Interior photo   1080x1080"
              buttonLabel="Add interior photo"
              type="INTERIOR"
              images={currentFiles}
              uploadPhoto={updatePhoto}
            />
          </Grid>
        </Grid>

        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              isLoading ||
              (isEmpty(getDiff(values, modalProps)) &&
                differenceWith(
                  Object.values(currentFiles),
                  modalProps.images,
                  isEqual
                ).length === 0) ||
              !isEmptyPhotos
            }
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
export default EditProfile;
