import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { ROUTES } from "../../constants";
import winSpin from "../../assets/swiggle.png";
import { CodeStep, EmailStep, LoginStep, SetPasswordStep } from "./blocks";

export enum LoginSteps {
  EMAIL_STEP = "email-step",
  LOGIN_STEP = "login-step",
  CODE_STEP = "code-step",
  PASS_STEP = "pass-step",
  RESSET_PASSWORD = "reset-password",
}

export const CustomInput = styled(TextField)(() => ({
  background: "#1A162C",
  border: "1px solid #342D5C",
  marginBottom: "10px",
  borderRadius: "5px",
  "& .MuiInputLabel-root": {
    color: "#7477A0",
  },
  "& .MuiFilledInput-input": {
    color: "#7477A0",
  },
}));

export const CustomWrapper = styled(Box)(() => ({
  color: "#ffffff",
  background:
    " transparent linear-gradient(337deg, #FC6CAC -50%, #201A3E 50%, #8466F4 100%)",
}));

export const useStyles = makeStyles(() => ({
  customForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "350px",
    width: "100%",
  },
  icon: {
    color: "#7477A0",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    fontSize: "10px",
    "& .MuiFormControl-root": {
      "&>label": {
        color: "#7477A0",
      },
    },
    "&>span": {
      marginLeft: "5px",
      color: "#fe4c17",
    },
  },
  title: {
    marginBottom: "10px",
  },
  input: {
    marginBottom: "15px",
  },
  resetBtn: {
    background: "transparent",
    border: 0,
    color: "white",
    margin: "20px",
    cursor: "pointer",
  },
}));

interface Props {
  isAuth: boolean;
}

const LoginPage = ({ isAuth }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = React.useState(LoginSteps.EMAIL_STEP);
  const [currentEmail, setCurrentEmail] = React.useState();
  const [currentTokens, setCurrentTokens] = React.useState();

  const getStepContent = (step: LoginSteps) => {
    switch (step) {
      case LoginSteps.CODE_STEP:
        return (
          <CodeStep
            setCurrentStep={setCurrentStep}
            currentEmail={currentEmail}
            setCurrentTokens={setCurrentTokens}
          />
        );
      case LoginSteps.LOGIN_STEP:
        return (
          <LoginStep
            setCurrentStep={setCurrentStep}
            currentEmail={currentEmail}
          />
        );
      case LoginSteps.PASS_STEP:
        return (
          <SetPasswordStep
            setCurrentStep={setCurrentStep}
            currentEmail={currentEmail}
            currentTokens={currentTokens}
          />
        );
      default:
        return (
          <EmailStep
            setCurrentStep={setCurrentStep}
            setCurrentEmail={setCurrentEmail}
          />
        );
    }
  };

  if (isAuth) {
    return <Navigate to={`/${ROUTES.createProfile}`} />;
  }

  return (
    <CustomWrapper sx={{ flexGrow: 1 }}>
      <Container>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <img
            style={{
              marginBottom: "80px",
              maxWidth: "400px",
              height: "300px",
              width: "400px",
            }}
            src={winSpin}
            alt="logo"
          />
          {getStepContent(currentStep)}
          {(LoginSteps.EMAIL_STEP === currentStep ||
            LoginSteps.LOGIN_STEP === currentStep) && (
            <button
              className={classes.resetBtn}
              onClick={() => navigate(`/${ROUTES.resetPassword}`)}
            >
              Forgot password?
            </button>
          )}
        </Grid>
      </Container>
    </CustomWrapper>
  );
};
export default LoginPage;
