import React from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { makeStyles } from "@mui/styles";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { differenceInCalendarDays, add } from "date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";

import useFileUpload from "hooks/useFileUpload";
import DragAndDrop from "components/DragAndDrop";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { ModalInput, CustomSelect } from "components/inputs";

const useStyles = makeStyles({
  containerModal: {
    height: "350px",
    overflow: "scroll",
    paddingTop: "15px",
  },
  contentWrapper: {
    padding: "20px",
    width: "650px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    "& > button": {
      margin: "0 10px",
      minWidth: "90px",
    },
  },
  item: {
    position: "relative",
    "&>span": {
      color: "#ff0000",
      fontSize: "10px",
      left: "10px",
      bottom: "5px",
    },
    "& > .MuiFormControl-root": {
      width: "95%",
    },
    "& svg": {
      color: "rgb(116, 119, 160)",
    },
  },
  dateItem: {
    "& > .MuiFormControl-root": {
      width: "97.5%",
    },
    "& svg": {
      color: "rgb(116, 119, 160)",
    },
  },
  textFieled: {
    "& textarea": {
      color: "#7477a0",
    },
  },
  itemFullWith: {
    "& > .MuiFormControl-root": {
      width: "97.5%",
    },
  },
  itemPhoto: {
    "& > .MuiFormControl-root": {
      width: "95.5%",
    },
  },
  errorText: {
    fontSize: "10px",
    position: "relative",
    top: "-7px",
    left: "4px",
    color: "#b30000",
  },
});

interface Props {
  handleClose: any;
}
interface PrizeParams {
  name: string;
  prizeID: number;
  prizeCount: number;
  expiredAt?: string;
  categories: {
    id: number;
    value: string;
  };
}

export const editPrizeList = (value: PrizeParams): Promise<any> =>
  axios.post(`/retailer-prize/prize`, value).then((res) => {
    return res;
  });

const AddCampaign = ({ handleClose }: Props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-height:700px)");
  const queryClient = useQueryClient();

  const [categoriesOptions, setCategoriesOptions] = React.useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
    resetField,
    clearErrors,
    control,
  } = useForm({
    defaultValues: {
      prizeType: "REGULAR",
      price: 0,
      prizeCount: 0,
      expiredAt: add(new Date(), {
        days: 1,
      }),
      name: "",
      categories: [],
      ageLimit: "",
      perCustomerLimit: null,
      terms: "",
    },
    mode: "all",
  });

  const datePlusYear = new Date();
  datePlusYear.setDate(datePlusYear.getDate() + 365);

  const { mutate, isLoading, isSuccess, error } = useMutation(
    (value: PrizeParams) => editPrizeList(value)
  );
  const formValues = watch();

  React.useEffect(() => {
    axios.get(`/retailer-prize/category`).then(({ data }) => {
      setCategoriesOptions(data);
    });
  }, []);

  React.useEffect(() => {
    if (formValues.price < 0) {
      setValue("price", 0);
    }
    if (formValues.prizeCount < 0) {
      setValue("prizeCount", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const { currentFiles, updatePhoto, patchPhotos } = useFileUpload();

  const isEmptyPhotos = Object.values(currentFiles)
    .reduce((acc: boolean[], value: any) => {
      return [...acc, isEmpty(value) || value?.isCropped === false];
    }, [])
    .every((v) => v === false);

  const [dateValue, setDateValue] = React.useState<Date | null>(
    add(new Date(), {
      days: 1,
    })
  );

  const handleClick = async (data: any) => {
    const parsedData = {
      ...data,
      categories: data.categories.map((item: string) => JSON.parse(item)),
      ageLimit: Number(data.ageLimit.slice(0, -1)),
      perCustomerLimit:
        data.perCustomerLimit === "no limit"
          ? null
          : Number(data.perCustomerLimit.slice(0, -1)),
    };

    await mutate(parsedData as PrizeParams, {
      onSuccess: async ({ data }: any) => {
        await patchPhotos(
          `/retailer-prize/prize/${data?.prizeId}/image`,
          `/retailer-prize/prize/${data?.prizeId}/image`
        );
      },
    });
  };
  if (isSuccess) {
    handleClose();
    setTimeout(() => {
      queryClient.invalidateQueries("сampaignsList");
    }, 500);
  }

  React.useEffect(() => {
    if ((error as any)?.response?.data?.code === "error.wrong-prize-type") {
      setError("prizeType", { type: "invalidType" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(error as any)?.response?.data?.code]);

  return (
    <div className={classes.contentWrapper}>
      <form
        className={classes.form}
        onSubmit={handleSubmit((data) => {
          const { expiredAt } = data;
          if (expiredAt) {
            if (differenceInCalendarDays(expiredAt, new Date()) < 1) {
              setError("expiredAt", { type: "invalidTime" });
            } else {
              clearErrors("expiredAt");
              handleClick(data);
            }
          }
        })}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add new Prize
        </Typography>
        <Grid
          container
          className={classNames({ [classes.containerModal]: matches })}
        >
          <Grid item xs={6} className={classes.item}>
            <ModalInput
              size="small"
              label="Describe offer"
              placeholder="Enter Offer"
              error={!!errors?.name}
              helperText={
                (errors?.name as any)?.type ? "Description Required" : ""
              }
              className={classNames({ required: errors?.name })}
              {...register("name", { required: true })}
            />
            {/* register your input into the hook by invoking the "register" function */}
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <ModalInput
              size="small"
              label="How many prizes are available? "
              placeholder="Enter Number"
              type="number"
              error={!!errors?.prizeCount}
              helperText={
                (errors?.prizeCount as any)?.type
                  ? (errors?.prizeCount as any)?.type === "max"
                    ? "Count shouldn't be more than 500"
                    : "Count is Required and should be more then 0"
                  : ""
              }
              className={classNames({ required: errors?.prizeCount })}
              {...register("prizeCount", {
                required: true,
                valueAsNumber: true,
                min: 1,
                max: 500,
              })}
            />
          </Grid>

          <Grid item xs={6} className={classes.item}>
            <ModalInput
              label="Value Of Prize, $"
              placeholder="Enter Value"
              type="number"
              size="small"
              error={!!errors?.price}
              helperText={
                (errors?.price as any)?.type
                  ? (errors?.price as any)?.type === "max"
                    ? "Price shouldn't be more than 500"
                    : "Price is Required and should be more then 0"
                  : ""
              }
              className={classNames({ required: errors?.price })}
              {...register("price", {
                valueAsNumber: true,
                required: true,
                min: 1,
                max: 500,
              })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <CustomSelect
              {...control}
              options={["1x", "2x", "3x", "no limit"]}
              errorMessage="Redemption per user is Required"
              label="Choose Redemption per user"
              selectLabel="Choose Redemption per user"
              placeholder="Choose Redemption per user"
              name="perCustomerLimit"
              size="small"
              multiple={false}
              isCommon
              errors={errors}
              className={classNames({ required: errors?.perCustomerLimit })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <CustomSelect
              {...control}
              options={["18+", "21+", "25+", "30+", "40+"]}
              errorMessage="Age Limit Required"
              label="Choose Age Limit"
              selectLabel="Choose Age Limit"
              placeholder="Choose Age Limit"
              name="ageLimit"
              size="small"
              multiple={false}
              isCommon
              errors={errors}
              className={classNames({ required: errors?.ageLimit })}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <CustomSelect
              {...control}
              options={categoriesOptions}
              errors={errors}
              size="small"
              className={classNames({ required: errors?.categories })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classNames(classes.item, classes.dateItem)}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disablePast
                minDate={add(new Date(), {
                  days: 1,
                })}
                maxDate={datePlusYear}
                renderInput={(props: any) => (
                  <ModalInput
                    size="small"
                    className={classNames({ required: errors?.expiredAt })}
                    {...props}
                    {...register("expiredAt", { valueAsDate: true })}
                  />
                )}
                label="Expiration Date"
                value={dateValue}
                onChange={(newDate) => {
                  if (newDate) {
                    if (differenceInCalendarDays(newDate, new Date()) <= 0) {
                      setError("expiredAt", { type: "invalidTime" });
                    } else {
                      resetField("expiredAt");
                    }
                    setValue("expiredAt", newDate);
                    setDateValue(newDate);
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} className={classes.itemFullWith}>
            <ModalInput
              label="Enter your terms and conditions"
              placeholder="list any restrictions on when a user can redeem this prize"
              multiline
              rows={4}
              className={classNames([classes.textFieled], {
                required: errors?.terms,
              })}
              error={!!errors?.terms}
              helperText={
                (errors?.terms as any)?.type
                  ? (errors?.terms as any)?.type === "maxLength"
                    ? "Terms and conditions shouldn't contain more than 1500 letters"
                    : "Terms and conditions is Required"
                  : ""
              }
              {...register("terms", {
                required: true,
                minLength: 1,
                maxLength: 1500,
              })}
            />
          </Grid>
          <Grid item xs={6} className={classes.itemPhoto}>
            <DragAndDrop
              name="prize-photo-big"
              label="Big prize photo 1080x1080"
              type="BIG"
              images={currentFiles}
              uploadPhoto={updatePhoto}
            />
          </Grid>
          <Grid item xs={6} className={classes.itemPhoto}>
            <DragAndDrop
              name="prize-photo-smal"
              label="Small prize photo 662x280"
              type="SMALL"
              images={currentFiles}
              uploadPhoto={updatePhoto}
            />
          </Grid>
        </Grid>

        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading || !isEmpty(errors) || !isEmptyPhotos}
            type="submit"
          >
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};
export default AddCampaign;
