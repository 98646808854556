import { createContext } from "react";
import { UserInfo } from "models/user";

interface UserContextModel {
  user: UserInfo | null;
  setData: (data: UserInfo | null) => void;
}

const UserContext = createContext<UserContextModel>({
  user: null,
  setData: () => {},
});

export default UserContext;
